import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { GalleryPayload, Resource } from '@types'

const resourcesUrl = '/agency/recursos'

const resourcesApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getResourceById: builder.query<Resource, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery(resourcesUrl),
      providesTags: (_result, _error, { id }) => [{ type: 'Resource', id }],
    }),
    getResources: builder.query<Resource[], { clientId: number }>({
      query: getAllByClientIdQuery(resourcesUrl),
      providesTags: ['Resource'],
    }),
    createResource: builder.mutation<Resource, { data: Partial<Resource> }>({
      query: createOneMutation(resourcesUrl),

      invalidatesTags: ['ResourceTable'],
    }),
    deleteResource: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation(resourcesUrl),
      invalidatesTags: ['ResourceTable'],
    }),
    updateResource: builder.mutation<Resource, { id: number; data: Partial<Resource> }>({
      query: updateByIdMutation(resourcesUrl),
      invalidatesTags: ['Resource', 'ResourceTable'],
    }),
    uploadResourceFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation(resourcesUrl, 'galeria_archivos'),
      invalidatesTags: ['Resource', 'ResourceTable'],
    }),
  }),
  overrideExisting: false,
})
export const {
  useGetResourceByIdQuery,
  useGetResourcesQuery,
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useUploadResourceFilesMutation,
  useDeleteResourceMutation,
} = resourcesApi
