import * as React from 'react'
import { globalStyles } from '@styles/styles'
import { DynamicTable, ThumbnailCell, sortablePlainTextCell } from '@components/tables/DynamicTable'
import { useAppSelector } from '@redux/reduxHooks'
import { useSession } from '@hooks/useSession'
import { useGetInvestorCommunicationTableQuery, useGetOwnerCommunicationTableQuery } from '@endpoints/tablesEndpoint'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Drawer,
  Button,
  Typography,
  Stack,
  ModalClose,
  AspectRatio,
  Grid,
  FormLabel,
  LinearProgress,
  Skeleton,
  IconButton,
  styled,
  Box,
} from '@mui/joy'
import { JoyStyles, UserCommunication, VisitRecordRow } from '@types'
import { useOwnerNews } from '@hooks/useOwnerNews'
import RichTextEditor from '@components/RichTextEditor'
import { useAppraisal } from '@hooks/useAppraisal'
import moment from 'moment'
import { useEffect } from 'react'
import { Rating } from '@mui/material'
import { useReport } from '@hooks/useReport'

import { chartsColors, routes } from '@constants'
import {
  AmenitiesItem,
  ContentCarousel,
  InformationItem,
  PieCard,
  PreviewHeader,
  PreviewSection,
  PropertyStripe,
  RatingCard,
  ValuationCard,
} from '@components/PrevewComponents'
import { useInvestorNews } from '@hooks/useInvestorNews'
import { ArrowDownTrayIcon, ArrowLongRightIcon, CalendarIcon, HomeIcon, UserIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarSolid } from '@heroicons/react/24/solid'
import ReactImageGallery from 'react-image-gallery'
import { ReferencePointGrid } from '@components/ReferencePointGrid'
import { BaseModal } from '@components/JoyConfirmationModal'
import { navigate } from 'gatsby'
import { isContentEmpty, isNotFound } from '@utils'

interface DrawerState {
  open: boolean
  id?: number
  type?: UserCommunication['type']
}
interface UserCommunicationsTableProps {
  defaultDrawerState?: DrawerState
  source: 'owner_view' | 'investor_view'
}

export const UserCommunicationsTable = ({
  defaultDrawerState = { open: false },
  source,
}: UserCommunicationsTableProps) => {
  const { client } = useSession()
  const isBrowser = typeof window !== 'undefined'
  const noTrailingSlashLocation = isBrowser ? location.pathname.replace(/\/$/, '') : ''
  const tableState = useAppSelector(
    (state) => state.tableConfigs[noTrailingSlashLocation as keyof typeof state.tableConfigs],
  )
  const { search = '', sorted = {}, filter = {} } = tableState ?? {}
  const skip = !client?.id

  const [drawer, setDrawer] = React.useState<DrawerState>(defaultDrawerState)

  const {
    data: communications,
    isFetching: isFetchingComuunications,
    isLoading: isLoadingComuunications,
    isError: isErrorComuunications,
  } = (source === 'owner_view' ? useGetOwnerCommunicationTableQuery : useGetInvestorCommunicationTableQuery)(
    {
      clientId: client?.id!,
      search: Number(search?.length) < 3 ? '' : search,
      sorted,
      filter,
      activeTab: 'all',
    },
    { skip },
  )

  const titles = {
    'Novedad de Propietarios': 'Novedades',
    'Novedad de Inversores': 'Novedades',
    Reporte: 'Reportes',
    Tasación: 'Tasación',
  }

  const [_location, setLocation] = React.useState('')

  useEffect(() => {
    const detailRoutes = {
      'Novedad de Propietarios': routes[source].owner_news_detail,
      'Novedad de Inversores': routes[source].investor_news_detail,
      Reporte: routes[source].report_detail,
      Tasación: routes[source].appraisal_detail,
    }
    window.history.replaceState(
      null,
      '',
      !drawer.open
        ? routes[source].communications //
        : detailRoutes[drawer.type!](drawer.id!),
    )
    setLocation(window.location.href)
  }, [drawer])

  useEffect(() => {
    if (defaultDrawerState.open) {
      setDrawer(defaultDrawerState)
    }
  }, [defaultDrawerState])

  return (
    <>
      <DynamicTable
        error={isErrorComuunications}
        isLoading={isLoadingComuunications}
        tableConfig={{
          thumbnail: { header: 'Portada' },
          communication_type: { header: 'Tipo' },
          title: { header: 'Título' },
          author: { header: 'Autor' },
          date: { header: 'Fecha/Período' },
          actions: { header: 'Acciones' },
        }}
        meta={communications?.meta}
        useBackendFilter
        options={{
          inactiveTabName: 'No activas (En borrador)',
          hiddenTabs: true,
          tableSelection: [
            {
              title: 'Comunicaciones',
              link: routes.owner_view.communications,
            },
          ],
        }}
        cards={communications?.results?.map((communication) => ({
          title: communication.title,
          date: communication.date,
          image: communication.thumbnail,
          fallbackJSX:
            communication.type === 'Tasación' ? (
              <StaticImage
                src={'../../images/MediaOwner/communications/appraisal.png'}
                alt=""
              />
            ) : communication.type === 'Reporte' ? (
              <StaticImage
                src={'../../images/MediaOwner/communications/report.png'}
                alt=""
              />
            ) : (
              <StaticImage
                src={'../../images/MediaOwner/communications/news.png'}
                alt=""
              />
            ),
          button: (
            <Button
              variant="outlined"
              color="neutral"
              sx={{
                ':not(:hover)': {
                  color: 'white',
                },
              }}
              endDecorator={
                <ArrowLongRightIcon
                  style={{ color: 'inherit' }}
                  width={'1em'}
                />
              }
              onClick={() => setDrawer({ open: true, id: communication.id, type: communication.type })}
            >
              <Typography level="body-md-light">Ver más</Typography>
            </Button>
          ),
        }))}
        rows={
          communications?.results?.map((communication) => ({
            activeRow: true,
            content: {
              thumbnail: {
                render: (
                  <ThumbnailCell
                    src={communication.thumbnail}
                    fallbackJSX={
                      communication.type === 'Tasación' ? (
                        <StaticImage
                          className="thumbnail"
                          src={'../../images/MediaOwner/communications/appraisal.png'}
                          alt=""
                        />
                      ) : communication.type === 'Reporte' ? (
                        <StaticImage
                          className="thumbnail"
                          src={'../../images/MediaOwner/communications/report.png'}
                          alt=""
                        />
                      ) : (
                        <StaticImage
                          className="thumbnail"
                          src={'../../images/MediaOwner/communications/news.png'}
                          alt=""
                        />
                      )
                    }
                  />
                ),
              },
              communication_type: sortablePlainTextCell(communication.type),
              title: sortablePlainTextCell(communication.title, 50),
              author: sortablePlainTextCell(communication.author),
              date: sortablePlainTextCell(communication.date),
              actions: {
                render: (
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="plain"
                      color="primary"
                      onClick={() => setDrawer({ open: true, id: communication.id, type: communication.type })}
                    >
                      <Typography endDecorator={<ArrowLongRightIcon style={{ width: '1em' }} />}>Ver más</Typography>
                    </Button>
                  </td>
                ),
              },
            },
          })) ?? []
        }
        fetching={isFetchingComuunications}
      />
      <Drawer
        anchor="right"
        open={drawer.open}
        onClose={() => setDrawer({ open: false })}
        slotProps={globalStyles.formDrawerSlotProps}
      >
        {drawer.open && (
          <>
            <DrawerTitle title={titles[drawer.type!]} />
            {drawer.type === 'Novedad de Propietarios' && <OwnerNewsPreview id={drawer.id!} />}
            {drawer.type === 'Novedad de Inversores' && <InvestorNewsPreview id={drawer.id!} />}
            {drawer.type === 'Tasación' && <AppraisalPreview id={drawer.id!} />}
            {drawer.type === 'Reporte' && <ReportPreview id={drawer.id!} />}
          </>
        )}
      </Drawer>
    </>
  )
}

export const DrawerTitle = ({ title, isLoading }: { title: React.ReactNode; isLoading?: boolean }) => (
  <Stack
    justifyContent="space-between"
    direction="row"
    alignItems="center"
    sx={{
      paddingX: 3,
      minHeight: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    }}
  >
    {typeof title === 'string' ? (
      <Typography level="h3">
        <Skeleton loading={Boolean(isLoading)}>{title}</Skeleton>
      </Typography>
    ) : (
      title
    )}
    <ModalClose size="lg" />
  </Stack>
)

const PreviewForm = ({ children, notFound }: { children: React.ReactNode; notFound?: boolean }) => {
  const contentRef = React.useRef<HTMLDivElement>(null)

  return (
    <Box
      ref={contentRef}
      sx={{
        position: 'relative',
      }}
    >
      {children}
      <BaseModal
        container={contentRef.current ?? undefined}
        icon="error"
        open={Boolean(notFound)}
        title="Elemento no encontrado"
        content="La comunicación a la que desea acceder no se encuentra disponible"
        buttons={
          <Button
            sx={{ width: '100%' }}
            onClick={() => navigate('/')}
          >
            Volver
          </Button>
        }
      />
    </Box>
  )
}
interface PreviewProps {
  id: number
}

export const OwnerNewsPreview = ({ id }: PreviewProps) => {
  const { ownerNews, isLoadingNews: isLoading, errorNews, isErrorNews } = useOwnerNews({ id })
  const isLoadingNews = isLoading || isErrorNews

  return (
    <PreviewForm notFound={isNotFound(errorNews)}>
      <PreviewHeader
        title={ownerNews.title}
        headline={ownerNews.headline}
        date={new Date(ownerNews.date_published ?? '').toLocaleDateString()}
        loading={isLoadingNews}
      />
      <Stack
        sx={{
          paddingX: 3,
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
        }}
      >
        {(Boolean(ownerNews?.image) || isLoadingNews) && (
          <AspectRatio>
            <Skeleton loading={isLoadingNews}>
              <img
                src={ownerNews?.image as string}
                alt=""
              />
            </Skeleton>
          </AspectRatio>
        )}
        <PreviewSection title="Información general">
          <RichTextEditor
            noToolbar
            readOnly
            content={ownerNews.content ?? ''}
            placeholder={''}
          />
        </PreviewSection>
        <PreviewSection title="Archivos para descargar">
          <Stack
            direction="row"
            gap={2}
            flexWrap="wrap"
          >
            {ownerNews.files_data?.map(({ file }, index) => (
              <Button
                component="a"
                key={index}
                variant="plain"
                color="primary"
                href={file as string}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  startDecorator={<ArrowDownTrayIcon fontSize="small" />}
                  level="body-md-light"
                >
                  {(file as string).substring((file as string).lastIndexOf('/') + 1)}
                </Typography>
              </Button>
            ))}
          </Stack>
        </PreviewSection>
      </Stack>
    </PreviewForm>
  )
}

export const AppraisalPreview = ({ id }: PreviewProps) => {
  const { appraisal, isLoadingAppraisal: isLoading, isErrorAppraisal, errorAppraisal } = useAppraisal({ id })
  const isLoadingAppraisal = isLoading || isErrorAppraisal
  const noUnspecified = (value: string) => value !== 'Sin especificar' && value
  const mapCenter = {
    lat: Number(appraisal.content?.geo_lat ?? -34.60373472685532),
    lng: Number(appraisal.content?.geo_long ?? -58.3815675939131),
  }

  const currentPropertyStripe = (
    <PropertyStripe
      image={appraisal.images_data?.[0]?.file as string}
      title={appraisal.content?.real_address}
      subtitle={`${appraisal.content?.floor} - ${appraisal.content?.apartment}`}
      city={appraisal.content?.city}
      state={appraisal.content?.state}
      country={appraisal.content?.country}
      age={appraisal.content?.age}
      computable_total_surface={
        Number(appraisal.content?.roofed_surface ?? 0) +
        Number(appraisal.content?.semiroofed_surface ?? 0) * 0.5 +
        Number(appraisal.content?.unroofed_surface ?? 0) * 0.3
      }
      roofed_surface={appraisal.content?.roofed_surface}
      semiroofed_surface={appraisal.content?.semiroofed_surface}
      unroofed_surface={appraisal.content?.unroofed_surface}
      room_amount={appraisal.content?.room_amount}
      suite_amount={appraisal.content?.suite_amount}
      bathroom_amount={appraisal.content?.bathroom_amount}
      parking_lot_amount={appraisal.content?.parking_lot_amount}
      extra_amenities={appraisal.content?.extra_amenities}
      square_meter_value={'-'}
      tokko_valuation_price={'-'}
    />
  )

  const styles: JoyStyles = {
    content: {
      padding: 3,
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
    },
    generalInfoIcon: {
      width: 45,
      borderRadius: '50%',
      border: '1px solid var(--joy-palette-primary-plainDisabledColor)',
      justifyContent: 'center',
    },
    imageLegend: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      paddingY: 2,
      textAlign: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
    },
    comparisonContent: {
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      paddingX: 2,
      scrollBehavior: 'smooth',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }

  const iconStyle = { color: 'var(--joy-palette-primary-plainDisabledColor)', width: '20px' }

  return (
    <PreviewForm notFound={isNotFound(errorAppraisal)}>
      <PreviewHeader
        title={appraisal.title}
        headline={'Te contamos un poco sobre esta propiedad'}
        date={new Date(appraisal.date_published ?? '').toLocaleDateString()}
        loading={isLoadingAppraisal}
      />
      <Stack sx={styles.content}>
        {(Boolean(appraisal.images_data?.[0]) || isLoadingAppraisal) && (
          <AspectRatio>
            <Skeleton loading={isLoadingAppraisal}>
              <img
                src={appraisal.images_data?.[0]?.file as string}
                alt=""
              />
            </Skeleton>
          </AspectRatio>
        )}
        <PreviewSection title="Información general">
          <Grid
            container
            spacing={2}
          >
            {[
              {
                label: 'Inmueble',
                value: `${appraisal.content?.real_address}, ${appraisal.content?.neighborhood}, ${appraisal.content?.state}`,
                icon: <HomeIcon style={iconStyle} />,
              },
              {
                label: 'Fecha de realización',
                value: moment(appraisal.content?.created_at).locale('es').format('D [de] MMMM [de] YYYY'),
                icon: <CalendarIcon style={iconStyle} />,
              },
              {
                label: 'Realizado por',
                value: appraisal.author,
                icon: <UserIcon style={iconStyle} />,
              },
            ].map(({ label, value, icon }) => (
              <Grid
                key={label}
                xs={6}
                sm={3}
              >
                <Stack
                  direction="row"
                  gap={2}
                >
                  <Stack>
                    <AspectRatio
                      ratio="1"
                      variant="plain"
                      color="neutral"
                      sx={styles.generalInfoIcon}
                    >
                      <div>{icon}</div>
                    </AspectRatio>
                  </Stack>
                  <Stack>
                    <Typography level="title-lg-bold">{label}</Typography>
                    <Typography
                      level="body-md-light"
                      color="neutral"
                    >
                      {value}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </PreviewSection>
        <PreviewSection title="Descripción">
          <RichTextEditor
            noToolbar
            readOnly
            content={appraisal.content?.description ?? ''}
            placeholder={''}
          />
        </PreviewSection>
        <PreviewSection title="Resumen">
          <ContentCarousel
            items={[
              {
                title: 'Información',
                content: (
                  <Stack gap={2}>
                    <Typography level="h2">{'Información'}</Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      {[
                        {
                          alwaysShow: false,
                          header: 'Tipo de propiedad',
                          value: appraisal.content?.property_type?.join(', '),
                        },
                        { alwaysShow: false, header: 'Antigüedad', value: appraisal.content?.age },
                        { alwaysShow: false, header: 'Orientación', value: appraisal.content?.orientation },
                        { alwaysShow: false, header: 'Disposición', value: appraisal.content?.disposition },
                        { alwaysShow: false, header: 'Luminosidad', value: appraisal.content?.luminosity },
                        {
                          alwaysShow: false,
                          header: 'Estado de conservación',
                          value: appraisal.content?.preservation_state,
                        },
                        {
                          alwaysShow: false,
                          header: 'Calidad de construcción',
                          value: appraisal.content?.building_quality,
                        },
                        {
                          alwaysShow: false,
                          header: 'Expensas',
                          value: appraisal.content?.expenses,
                          valueToShow: `${appraisal.content?.expenses_currency} ${Number(
                            appraisal.content?.expenses,
                          ).toLocaleString('es-AR')}`,
                        },
                        { alwaysShow: true, header: 'Ambientes', value: appraisal.content?.room_amount },
                        { alwaysShow: true, header: 'Dormitorios', value: appraisal.content?.suite_amount },
                        { alwaysShow: true, header: 'Baños', value: appraisal.content?.bathroom_amount },
                        { alwaysShow: true, header: 'Toilettes', value: appraisal.content?.toilet_amount },
                        { alwaysShow: true, header: 'Cocheras', value: appraisal.content?.parking_lot_amount },
                        {
                          alwaysShow: false,
                          header: 'Tipo de cochera',
                          value: !!appraisal.content?.parking_lot_type && !!appraisal.content?.parking_lot_amount,
                          valueToShow: appraisal.content?.parking_lot_type,
                        },
                      ].map((item, index) => (
                        <InformationItem
                          key={index}
                          {...item}
                        />
                      ))}
                    </Grid>
                  </Stack>
                ),
              },
              {
                title: 'Superficie de la propiedad',
                content: (
                  <Stack gap={2}>
                    <Typography level="h2">{'Superficie de la propiedad'}</Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      {[
                        {
                          alwaysShow: false,
                          header: 'Sup. total',
                          value: appraisal.content?.total_surface,
                          valueToShow:
                            `${appraisal.content?.total_surface} ${appraisal.content?.total_surface_measurement}`.trim(),
                        },
                        {
                          alwaysShow: false,
                          header: 'Sup. cubierta',
                          value: appraisal.content?.roofed_surface,
                          valueToShow:
                            `${appraisal.content?.roofed_surface} ${appraisal.content?.roofed_surface_measurement}`.trim(),
                        },
                        {
                          alwaysShow: false,
                          header: 'Sup. semidescubierta',
                          value: appraisal.content?.semiroofed_surface,
                          valueToShow:
                            `${appraisal.content?.semiroofed_surface} ${appraisal.content?.semiroofed_surface_measurement}`.trim(),
                        },
                        {
                          alwaysShow: false,
                          header: 'Sup. descubierta',
                          value: appraisal.content?.unroofed_surface,
                          valueToShow:
                            `${appraisal.content?.unroofed_surface} ${appraisal.content?.unroofed_surface_measurement}`.trim(),
                        },
                      ].map((item, index) => (
                        <InformationItem
                          key={index}
                          {...item}
                        />
                      ))}
                    </Grid>
                  </Stack>
                ),
              },
              {
                title: 'Dimensiones de terreno',
                content: (
                  <Stack gap={2}>
                    <Typography level="h2">{'Dimensiones de terreno'}</Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      {[
                        {
                          alwaysShow: true,
                          header: 'Sup. terreno',
                          value: appraisal.content?.surface,
                          valueToShow: `${appraisal.content?.surface} ${appraisal.content?.surface_measurement}`.trim(),
                        },
                        {
                          alwaysShow: true,
                          header: 'Frente',
                          value: appraisal.content?.front_measure,
                          valueToShow:
                            `${appraisal.content?.front_measure} ${appraisal.content?.front_measurement}`.trim(),
                        },
                        {
                          alwaysShow: true,
                          header: 'Fondo',
                          value: appraisal.content?.depth_measure,
                          valueToShow:
                            `${appraisal.content?.depth_measure} ${appraisal.content?.depth_measurement}`.trim(),
                        },
                      ].map((item, index) => (
                        <InformationItem
                          key={index}
                          {...item}
                        />
                      ))}
                    </Grid>
                  </Stack>
                ),
              },
            ]}
          />
        </PreviewSection>
        <PreviewSection title="Ubicación">
          <Stack gap={3}>
            <ContentCarousel
              items={[
                {
                  title: 'Información',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Información'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {[
                          { alwaysShow: false, header: 'Dirección', value: appraisal.content?.real_address },
                          { alwaysShow: false, header: 'Piso', value: appraisal.content?.floor },
                          { alwaysShow: false, header: 'Depto', value: appraisal.content?.apartment },
                          { alwaysShow: false, header: 'País', value: appraisal.content?.country },
                          { alwaysShow: false, header: 'Provincia', value: appraisal.content?.state },
                          { alwaysShow: false, header: 'Zona', value: appraisal.content?.region },
                          { alwaysShow: false, header: 'Partido', value: appraisal.content?.city },
                          { alwaysShow: false, header: 'Barrio', value: appraisal.content?.neighborhood },
                        ].map((item, index) => (
                          <InformationItem
                            key={index}
                            {...item}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
                {
                  title: 'Análisis de la zona',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Análisis de la zona'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {[
                          { alwaysShow: false, header: 'Zona', value: appraisal.content?.construction_types },
                          { alwaysShow: false, header: 'Zonificación', value: appraisal.content?.zonification },
                          {
                            alwaysShow: false,
                            header: 'Índice de crecimiento',
                            value: noUnspecified(appraisal.content?.growth_rate ?? ''),
                            valueToShow: appraisal.content?.growth_rate,
                          },
                          {
                            alwaysShow: false,
                            header: 'Calidad de ubicación',
                            value: noUnspecified(appraisal.content?.location_quality ?? ''),
                            valueToShow: appraisal.content?.location_quality,
                          },
                          {
                            alwaysShow: false,
                            header: 'Seguridad',
                            value: noUnspecified(appraisal.content?.security ?? ''),
                            valueToShow: appraisal.content?.security,
                          },
                          {
                            alwaysShow: false,
                            header: 'Demanda',
                            value: noUnspecified(appraisal.content?.demand ?? ''),
                            valueToShow: appraisal.content?.demand,
                          },
                          {
                            alwaysShow: false,
                            header: 'Oferta',
                            value: noUnspecified(appraisal.content?.supply ?? ''),
                            valueToShow: appraisal.content?.supply,
                          },
                          {
                            alwaysShow: false,
                            header: 'Valores',
                            value: noUnspecified(appraisal.content?.values ?? ''),
                            valueToShow: appraisal.content?.values,
                          },
                        ].map((item, index) => (
                          <InformationItem
                            key={index}
                            {...item}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
              ]}
            />
            <Stack>
              <FormLabel>Geolocalización y puntos de referencia</FormLabel>
              <div
                className="mb-3"
                style={{ height: '250px' }}
              >
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${mapCenter.lat},${
                    mapCenter.lng
                  }&zoom=13&size=640x400&scale=2&key=${
                    process.env.GATSBY_GOOGLE_MAPS_API_KEY
                  }&style=saturation:-100&markers=size:small%7C${mapCenter.lat},${mapCenter.lng}${
                    appraisal?.content?.reference_points
                      ?.map(
                        (rp: { lat: number; lng: number }, index: number) =>
                          `&markers=size:small%7Ccolor:0x${chartsColors[index].slice(1)}%7C${rp.lat},${rp.lng}`,
                      )
                      ?.join('') ?? ''
                  }`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                ></img>
              </div>
              <Grid
                container
                spacing={2}
              >
                {Boolean(appraisal?.content?.reference_points?.length) && (
                  <ReferencePointGrid
                    firstPointName={appraisal?.content?.real_address}
                    reference_points={appraisal?.content?.reference_points}
                  />
                )}
              </Grid>
            </Stack>
          </Stack>
        </PreviewSection>
        <PreviewSection title="Adicionales y amenities">
          <ContentCarousel
            items={[
              {
                title: 'Servicios',
                content: (
                  <Stack gap={2}>
                    <Typography level="h2">{'Servicios'}</Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      {appraisal.content?.extra_services?.map((value: string, index: number) => (
                        <AmenitiesItem
                          key={index}
                          value={value}
                        />
                      ))}
                    </Grid>
                  </Stack>
                ),
              },
              {
                title: 'Generales',
                content: (
                  <Stack gap={2}>
                    <Typography level="h2">{'Generales'}</Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      {appraisal.content?.extra_general?.map((value: string, index: number) => (
                        <AmenitiesItem
                          key={index}
                          value={value}
                        />
                      ))}
                    </Grid>
                  </Stack>
                ),
              },
              {
                title: 'Amenities',
                content: (
                  <Stack gap={2}>
                    <Typography level="h2">{'Amenities'}</Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      {appraisal.content?.extra_amenities?.map((value: string, index: number) => (
                        <AmenitiesItem
                          key={index}
                          value={value}
                        />
                      ))}
                    </Grid>
                  </Stack>
                ),
              },
            ]}
          />
        </PreviewSection>
        <PreviewSection title="Imágenes de la propiedad">
          <Grid
            container
            spacing={4}
          >
            {appraisal.images_data?.map((image, index) => (
              <Grid
                key={index}
                xs={12}
                sm={4}
              >
                <AspectRatio sx={{ position: 'relative', borderRadius: globalStyles.borderRadius }}>
                  <img
                    src={image.file as string}
                    alt=""
                  />
                  {Boolean(image.text_alt) && <Typography sx={styles.imageLegend}>{image.text_alt}</Typography>}
                </AspectRatio>
              </Grid>
            ))}
          </Grid>
        </PreviewSection>
        {Boolean(appraisal.content?.rentComparisonProperties) && (
          <PreviewSection title="Comparable de propiedades - Alquiler">
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                sm={3}
              >
                {currentPropertyStripe}
              </Grid>
              <Grid
                xs={12}
                sm={9}
              >
                <Stack
                  direction="row"
                  gap={2}
                  sx={styles.comparisonContent}
                >
                  {appraisal.content?.rentComparisonProperties?.map((property: any, index: number) => (
                    <PropertyStripe
                      key={index}
                      image={property.frontCover ?? ''}
                      title={property.full_address ?? ''}
                      subtitle={'' ?? ''}
                      city={property.city ?? ''}
                      minWidth={'33%' ?? ''}
                      state={property.state ?? ''}
                      country={property.country ?? ''}
                      age={property.age ?? ''}
                      computable_total_surface={property.computable_total_surface ?? ''}
                      roofed_surface={property.roofed_surface ?? ''}
                      semiroofed_surface={property.semiroofed_surface ?? ''}
                      unroofed_surface={property.unroofed_surface ?? ''}
                      room_amount={property.room_amount ?? ''}
                      suite_amount={property.suite_amount ?? ''}
                      bathroom_amount={property.bathroom_amount ?? ''}
                      parking_lot_amount={property.parking_lot_amount ?? ''}
                      extra_amenities={property.extra_amenities ?? ''}
                      square_meter_value={property.square_meter_value ?? ''}
                      tokko_valuation_price={property.tokko_valuation_price ?? ''}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </PreviewSection>
        )}
        {Boolean(appraisal.content?.temporaryRentComparisonProperties) && (
          <PreviewSection title="Comparable de propiedades - Alquiler temporario">
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                sm={3}
              >
                {currentPropertyStripe}
              </Grid>
              <Grid
                xs={12}
                sm={9}
              >
                <Stack
                  direction="row"
                  gap={2}
                  sx={styles.comparisonContent}
                >
                  {appraisal.content?.temporaryRentComparisonProperties?.map((property: any, index: number) => (
                    <PropertyStripe
                      key={index}
                      image={property.frontCover ?? ''}
                      title={property.full_address ?? ''}
                      subtitle={'' ?? ''}
                      city={property.city ?? ''}
                      minWidth={'33%' ?? ''}
                      state={property.state ?? ''}
                      country={property.country ?? ''}
                      age={property.age ?? ''}
                      computable_total_surface={property.computable_total_surface ?? ''}
                      roofed_surface={property.roofed_surface ?? ''}
                      semiroofed_surface={property.semiroofed_surface ?? ''}
                      unroofed_surface={property.unroofed_surface ?? ''}
                      room_amount={property.room_amount ?? ''}
                      suite_amount={property.suite_amount ?? ''}
                      bathroom_amount={property.bathroom_amount ?? ''}
                      parking_lot_amount={property.parking_lot_amount ?? ''}
                      extra_amenities={property.extra_amenities ?? ''}
                      square_meter_value={property.square_meter_value ?? ''}
                      tokko_valuation_price={property.tokko_valuation_price ?? ''}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </PreviewSection>
        )}
        {Boolean(appraisal.content?.saleComparisonProperties) && (
          <PreviewSection title="Comparable de propiedades - Venta">
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                sm={3}
              >
                {currentPropertyStripe}
              </Grid>
              <Grid
                xs={12}
                sm={9}
              >
                <Stack
                  direction="row"
                  gap={2}
                  sx={styles.comparisonContent}
                >
                  {appraisal.content?.saleComparisonProperties?.map((property: any, index: number) => (
                    <PropertyStripe
                      key={index}
                      image={property.frontCover ?? ''}
                      title={property.full_address ?? ''}
                      subtitle={'' ?? ''}
                      city={property.city ?? ''}
                      minWidth={'33%' ?? ''}
                      state={property.state ?? ''}
                      country={property.country ?? ''}
                      age={property.age ?? ''}
                      computable_total_surface={property.computable_total_surface ?? ''}
                      roofed_surface={property.roofed_surface ?? ''}
                      semiroofed_surface={property.semiroofed_surface ?? ''}
                      unroofed_surface={property.unroofed_surface ?? ''}
                      room_amount={property.room_amount ?? ''}
                      suite_amount={property.suite_amount ?? ''}
                      bathroom_amount={property.bathroom_amount ?? ''}
                      parking_lot_amount={property.parking_lot_amount ?? ''}
                      extra_amenities={property.extra_amenities ?? ''}
                      square_meter_value={property.square_meter_value ?? ''}
                      tokko_valuation_price={property.tokko_valuation_price ?? ''}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </PreviewSection>
        )}
        <PreviewSection title="Valuación final">
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12}
              sm={6}
            >
              <Stack>
                <FormLabel>Opinión de valor</FormLabel>
                <RichTextEditor
                  noToolbar
                  readOnly
                  content={appraisal.content?.valuation_review ?? ''}
                  placeholder={''}
                />
              </Stack>
            </Grid>
            <Grid
              xs={12}
              sm={6}
            >
              <Stack gap={2}>
                {Boolean(appraisal.content?.valuation_price) && (
                  <ValuationCard
                    title="Valor de venta de la propiedad"
                    valuation_currency={appraisal.content?.valuation_currency ?? ''}
                    valuation_price={appraisal.content?.valuation_price ?? 0}
                    valuation_price_has_percentage={!!appraisal.content?.valuation_price_percentage ?? false}
                    valuation_price_percentage={appraisal.content?.valuation_price_percentage ?? 0}
                  />
                )}
                {Boolean(appraisal.content?.valuation_price_rent) && (
                  <ValuationCard
                    title="Valor de alquiler de la propiedad"
                    valuation_currency={appraisal.content?.valuation_currency_rent ?? ''}
                    valuation_price={appraisal.content?.valuation_price_rent ?? 0}
                    valuation_price_has_percentage={!!appraisal.content?.valuation_price_rent_has_percentage}
                    valuation_price_percentage={appraisal.content?.valuation_price_rent_percentage}
                    valuation_period={appraisal.content?.valuation_period_rent}
                  />
                )}
                {Boolean(appraisal.content?.valuation_price_temporary_rent) && (
                  <ValuationCard
                    title="Valor de alquiler de la propiedad"
                    valuation_currency={appraisal.content?.valuation_currency_temporary_rent ?? ''}
                    valuation_price={appraisal.content?.valuation_price_temporary_rent ?? 0}
                    valuation_price_has_percentage={!!appraisal.content?.valuation_price_temporary_rent_has_percentage}
                    valuation_price_percentage={appraisal.content?.valuation_price_temporary_rent_percentage}
                    valuation_period={appraisal.content?.valuation_period_temporary_rent}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </PreviewSection>
        {Boolean(appraisal.content?.footnotes) && (
          <PreviewSection title="Notas al pie">
            <RichTextEditor
              noToolbar
              readOnly
              content={appraisal.content?.footnotes ?? ''}
              placeholder={''}
            />
          </PreviewSection>
        )}
      </Stack>
    </PreviewForm>
  )
}

export const ReportPreview = ({ id }: PreviewProps) => {
  const { report, isLoadingReport: isLoading, isErrorReport, errorReport, visitsDetail } = useReport({ id })
  const isLoadingReport = isLoading || isErrorReport
  const totalVisits = visitsDetail.length
  const averageSum = visitsDetail.reduce((sum, item) => sum + item.average, 0)
  const averageTotal = totalVisits === 0 ? 0 : Math.round((averageSum / totalVisits) * 2) / 2

  const getCalificationCounts = (visits: VisitRecordRow[]) => {
    const counts = [0, 0, 0, 0, 0]
    visits.forEach((visit) => {
      const roundedAverage = Math.round(visit.average)
      if (roundedAverage >= 1 && roundedAverage <= 5) {
        counts[5 - roundedAverage]++
      }
    })
    return counts
  }

  const calificationCounts = getCalificationCounts(visitsDetail)

  const webVisits = report.content?.external_websites_inquiries
    ?.map(({ other_source, source, visits_count = 0, count = 0, url = '' }: any) => [
      { label: source === 'Otro' ? other_source : source, link: url },
      count,
      visits_count,
    ])
    .concat([
      [
        { label: 'Sitio web inmobiliaria', link: report.content.real_estate_url },
        report.content?.real_estate_visits_count ?? 0,
        report.content?.real_estate_inquiries_count ?? 0,
      ],
    ])

  const totalWebVisits = webVisits?.reduce((acc: number, value: any) => acc + value[1], 0) ?? 0

  const inquiries = report.content?.inquiries?.map(({ source, count, other_source }: any) => [
    source === 'Otro' ? other_source : source,
    count ?? 0,
  ])

  const totalInquiries = inquiries?.reduce((acc: number, value: any) => acc + value[1], 0) ?? 0

  const styles: JoyStyles = {
    content: {
      paddingX: 3,
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
    },
    physicalMetrics: {
      border: globalStyles.border,
      borderRadius: globalStyles.bigCard.borderRadius,
      padding: '30px',
    },
    ratingCards: {
      maxHeight: '300px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }

  return (
    <PreviewForm notFound={isNotFound(errorReport)}>
      <PreviewHeader
        title={report.content?.fake_address}
        headline={report.title}
        date_from={new Date(report.date_from ?? '').toLocaleDateString()}
        loading={isLoadingReport}
        date_to={new Date(report.date_to ?? '').toLocaleDateString()}
      />
      <Stack sx={styles.content}>
        <PreviewSection title="Métricas">
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12}
              sm={inquiries?.length ? 6 : undefined}
            >
              <PieCard
                title="Medios Digitales"
                data={webVisits}
                total={totalWebVisits}
                headers={['Sitio', 'Consultas', 'Visitas']}
                totalLabel="Total visitas"
              />
            </Grid>
            {Boolean(inquiries?.length) && (
              <Grid
                xs={12}
                sm={6}
              >
                <PieCard
                  title="Medios Tradicionales"
                  data={inquiries}
                  total={totalInquiries}
                  headers={['Origen', 'Consultas']}
                  totalLabel="Total consultas"
                />
              </Grid>
            )}
          </Grid>
        </PreviewSection>
        {totalVisits !== 0 && (
          <PreviewSection title="Visitas">
            <Stack
              gap={4}
              sx={styles.physicalMetrics}
            >
              <Stack gap={2}>
                <Stack
                  direction="row"
                  gap={2}
                  alignItems={'center'}
                >
                  <Typography
                    level="h1"
                    sx={{ lineHeight: '100%' }}
                  >
                    {averageTotal}
                  </Typography>
                  <Stack sx={{ height: '100%' }}>
                    <Rating
                      sx={{ height: '100%' }}
                      size="small"
                      value={averageTotal}
                      precision={0.5}
                      readOnly
                    />
                    <Typography
                      sx={{ height: '100%' }}
                      level="body-sm-light"
                    >{`${totalVisits} valoraciones`}</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  {[5, 4, 3, 2, 1].map((value, index) => (
                    <Stack
                      direction="row"
                      key={index}
                      gap={1}
                    >
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress
                          size="sm"
                          determinate
                          sx={{ transform: 'translate(0, 7px)' }}
                          // value={15 * value}
                          value={(calificationCounts[5 - value] / visitsDetail.length) * 100}
                        />
                      </Box>
                      <Typography
                        endDecorator={
                          <StarSolid
                            style={{ color: 'var(--joy-palette-primary-plainDisabledColor)', width: '12px' }}
                          />
                        }
                      >
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Grid
                container
                spacing={4}
                sx={styles.ratingCards}
              >
                {visitsDetail?.map((visit, index) => (
                  <Grid
                    xs={12}
                    md={6}
                    key={index}
                  >
                    <RatingCard
                      name={visit.visitor}
                      date={visit.date_visit}
                      rating={visit.average}
                      comments={visit.comments}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </PreviewSection>
        )}
        {!isContentEmpty(report.footnotes ?? '') && (
          <PreviewSection title="Nota al pie">
            <RichTextEditor
              noToolbar
              readOnly
              content={report.footnotes ?? ''}
              placeholder={''}
            />
          </PreviewSection>
        )}
      </Stack>
    </PreviewForm>
  )
}

export const InvestorNewsPreview = ({ id }: PreviewProps) => {
  const { investorNews, isLoadingNews: isLoading, isErrorNews, errorNews } = useInvestorNews({ id })
  const isLoadingNews = isLoading || isErrorNews

  const galleryImages =
    investorNews?.images_data?.map((image) => ({
      original: image.file as string,
      thumbnail: image.file as string,
      description: image.text_alt,
      text_alt: image.text_alt,
    })) ?? []

  const StyledImageGallery = styled('div')({
    '& .image-gallery-left-nav .image-gallery-svg, & .image-gallery-right-nav .image-gallery-svg, & .image-gallery-fullscreen-button .image-gallery-svg':
      {
        width: '15px',
      },
    '& .image-gallery-thumbnail': {
      borderRadius: '8px',
    },
    '& .image-gallery-thumbnail-image': {
      borderRadius: '8px',
    },
    '& .image-gallery-thumbnail.active, & .image-gallery-thumbnail:hover, & .image-gallery-thumbnail:focus': {
      borderWidth: '2px',
      padding: '2px',
      borderColor: 'var(--joy-palette-primary-500, #0B6BCB)',
    },
  })

  return (
    <PreviewForm notFound={isNotFound(errorNews)}>
      <PreviewHeader
        title={investorNews?.title ?? ''}
        headline={investorNews?.headline ?? ''}
        date={new Date(investorNews?.date_published ?? '').toLocaleDateString()}
        author={investorNews?.author ?? ''}
        loading={isLoadingNews}
      />
      <Stack
        sx={{
          paddingX: 3,
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
        }}
      >
        {(Boolean(investorNews?.image) || isLoadingNews) && (
          <AspectRatio>
            <Skeleton loading={isLoadingNews}>
              <img
                src={investorNews?.image as string}
                alt=""
              />
            </Skeleton>
          </AspectRatio>
        )}
        <Typography
          level="body-sm-light"
          textAlign={'right'}
        >
          {investorNews?.footer ?? ''}
        </Typography>
        <PreviewSection title="Información general">
          <RichTextEditor
            noToolbar
            readOnly
            content={investorNews?.content ?? ''}
            placeholder={''}
          />
        </PreviewSection>
        {Boolean(investorNews?.images_data?.length) && (
          <PreviewSection title="Galería de imágenes">
            <StyledImageGallery>
              <ReactImageGallery
                showPlayButton={false}
                showIndex
                // showBullets
                autoPlay
                items={galleryImages}
              />
            </StyledImageGallery>
          </PreviewSection>
        )}
        {Boolean(investorNews?.files_data?.length) && (
          <PreviewSection title="Archivos para descargar">
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
            >
              {investorNews?.files_data?.map(({ file }, index) => (
                <IconButton
                  component="a"
                  key={index}
                  variant="outlined"
                  color="primary"
                  href={file as string}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none' }}
                >
                  <Typography
                    startDecorator={<ArrowDownTrayIcon style={{ width: '1em' }} />}
                    level="body-md-light"
                  >
                    {(file as string).substring((file as string).lastIndexOf('/') + 1)}
                  </Typography>
                </IconButton>
              ))}
            </Stack>
          </PreviewSection>
        )}
      </Stack>
    </PreviewForm>
  )
}
