import { KeyboardArrowDown } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  DialogActions,
  DialogTitle,
  Dropdown,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy'
import { TextTemplate, ValidationErrors } from '@types'
import { CheckIcon, EllipsisVerticalIcon, PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Popper } from '@mui/base/Popper'
import { ClickAwayListener } from '@mui/material'
import { globalStyles } from '@styles/styles'
import SpinnerCenter from '@components/SpinnerCenter'
import { FloatingLabel } from '@components/FloatingLabel'

interface Props {
  placeholder?: string
  options: TextTemplate[]
  onChangeSelection: (value: TextTemplate | null) => void
  onDeleteOption: (id: number) => void
  defaultValue: TextTemplate | undefined
  buttonDisabled?: boolean
  onSaveTextTemplate: (title?: string, id?: number) => Promise<void | TextTemplate>
  section?: string
  isBusy?: boolean
  error?: ValidationErrors
}
const TextLibrarySelector = ({
  placeholder,
  options,
  onChangeSelection,
  defaultValue,
  buttonDisabled,
  onSaveTextTemplate,
  onDeleteOption,
  isBusy,
  error,
}: Props) => {
  const inputRef = React.useRef<HTMLButtonElement>(null)

  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<TextTemplate | null>(defaultValue || null)
  const [textTemplatesModal, setTextTemplatesModal] = useState<{
    show: boolean
  }>({
    show: false,
  })
  const [showInput, setShowInput] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setSelectedOption(defaultValue || null)
  }, [defaultValue])

  const EditableOption = ({ option }: { option: TextTemplate; selected: boolean }) => {
    const editInputRef = useRef<HTMLInputElement>(null)

    const [newTitle, setNewTitle] = useState(option.title)
    const [editMode, setEditMode] = useState(false)

    return (
      <>
        {editMode ? (
          <Input
            ref={editInputRef}
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            onClick={(e) => {
              e.stopPropagation()
            }}
            endDecorator={
              <Stack
                direction="row"
                spacing={0.5}
              >
                <IconButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <CheckIcon
                    onClick={() => {
                      onSaveTextTemplate(newTitle, option.id!)
                    }}
                  />
                </IconButton>
                <IconButton>
                  <XMarkIcon onClick={() => setEditMode(false)} />
                </IconButton>
              </Stack>
            }
          />
        ) : (
          <Typography>{option.title}</Typography>
        )}
        {
          <ListItemDecorator
            onClick={(e) => {
              e.stopPropagation()
            }}
            sx={{ marginLeft: 'auto' }}
          >
            <Dropdown>
              <MenuButton
                variant="plain"
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
              >
                <EllipsisVerticalIcon style={{ width: '1em' }} />
              </MenuButton>
              <Menu sx={{ zIndex: 99999 }}>
                <MenuItem
                  onClick={() => {
                    setEditMode(true)
                  }}
                >
                  <Typography startDecorator={<PencilIcon fontSize="small" />}>Modificar</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDeleteOption(option.id)
                  }}
                >
                  <Typography startDecorator={<TrashIcon fontSize="small" />}>Eliminar</Typography>
                </MenuItem>
              </Menu>
            </Dropdown>
          </ListItemDecorator>
        }
      </>
    )
  }

  const handleSave = () => {
    const ttTitle =
      selectedOption && !showInput ? undefined : showInput || !selectedOption ? title : defaultValue?.title
    onSaveTextTemplate(ttTitle, !showInput ? selectedOption?.id : undefined)
      .then((response) => {
        if (response) {
          setTextTemplatesModal({ show: false })
          setSelectedOption(response)
          setShowInput(false)
          setTitle('')
        }
      })
      .catch(() => {})
  }

  const saveTextTemplatesModal = (
    <>
      <Modal
        open={textTemplatesModal.show}
        onClose={() => {
          setTextTemplatesModal({ show: false })
          setShowInput(false)
        }}
      >
        <ModalDialog>
          <DialogTitle sx={{ mb: 2 }}>Agregar texto a biblioteca</DialogTitle>
          <ModalClose />
          {!selectedOption || showInput ? (
            <FormControl error={!!error?.title}>
              <FloatingLabel label="Título">
                <Input
                  name="name"
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave()
                    }
                  }}
                  value={title}
                />
              </FloatingLabel>
              <FormHelperText>{error?.title ?? ''}</FormHelperText>
            </FormControl>
          ) : (
            <></>
          )}
          <DialogActions>
            {selectedOption && !showInput && <Button onClick={() => setShowInput(true)}>Crear nuevo</Button>}
            <Button
              onClick={handleSave}
              loading={isBusy}
            >
              {selectedOption && !showInput ? 'Sobreescribir existente' : 'Guardar en biblioteca'}
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => {
                setTextTemplatesModal({ show: false }), setShowInput(false)
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(true)
  }

  return (
    <>
      <Grid
        container
        spacing={1}
      >
        <Grid md={9}>
          <FloatingLabel label={'Biblioteca'}>
            <Button
              color="neutral"
              variant="outlined"
              endDecorator={<KeyboardArrowDown />}
              ref={inputRef}
              sx={{
                minHeight: globalStyles.inputHeight,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onMouseDown={handleClick}
              fullWidth
            >
              <Typography level="body-md-light">{selectedOption?.title ?? placeholder}</Typography>
            </Button>
          </FloatingLabel>
          {
            <Popper
              role={undefined}
              id="composition-menu"
              open={open}
              anchorEl={inputRef.current}
              disablePortal
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 4],
                  },
                },
              ]}
              style={{ width: inputRef.current?.offsetWidth, zIndex: 9999999999 }}
            >
              <ClickAwayListener
                onClickAway={(event) => {
                  if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                    setOpen(false)
                  }
                }}
              >
                <MenuList
                  variant="outlined"
                  sx={{ maxHeight: '30vh', overflowY: 'auto' }}
                >
                  {isBusy ? (
                    <SpinnerCenter />
                  ) : (
                    options.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          onChangeSelection(option)
                          setSelectedOption(option)
                          setOpen(false)
                        }}
                        sx={{
                          backgroundColor: selectedOption?.id === option.id ? 'background.level2' : 'inherit',
                          '&:hover': {
                            bgcolor: selectedOption?.id === option.id ? 'background.level3 !important' : '',
                          },
                        }}
                      >
                        <EditableOption
                          option={option}
                          selected={false}
                        />
                      </MenuItem>
                    ))
                  )}
                </MenuList>
              </ClickAwayListener>
            </Popper>
          }
        </Grid>
        <Grid md={3}>
          <Button
            fullWidth
            sx={{ height: '100%' }}
            disabled={buttonDisabled}
            onClick={() => setTextTemplatesModal({ show: true })}
          >
            Guardar en biblioteca
          </Button>
        </Grid>
      </Grid>
      {saveTextTemplatesModal}
    </>
  )
}

export default TextLibrarySelector
