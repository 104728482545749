import { FloatingLabel } from '@components/FloatingLabel'
import { FloatingLabelInput } from '@components/FloatingLabelInput'
import { FloatingLabelDateInput } from '@components/FloatingLabelDateInput'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Add, AddCircleOutline } from '@mui/icons-material'
import {
  FormControl,
  Select,
  Option,
  Textarea,
  Grid,
  Typography,
  Switch,
  Box,
  FormHelperText,
  Checkbox,
  Tooltip,
  FormLabel,
  Stack,
  Input,
  Button,
  IconButton,
} from '@mui/joy'
import { globalStyles } from '@styles/styles'
import { PickByType } from '@types'
import React, { ComponentProps, ReactNode, memo, useEffect, useReducer, useRef, useState } from 'react'
import { cleanString, realAddressComponents } from '@utils'
import { chartsColors, mapStyles } from '@constants'
import { Map, AdvancedMarker, useMap, Pin, useMapsLibrary } from '@vis.gl/react-google-maps'
import { GoogleIcon } from '@components/GoogleIcon'
import { useSession } from '@hooks/useSession'
import { useGetModulesQuery } from '@apis/mediacoreApi'

export const memoWithGenerics = memo as <P extends object>(
  Component: (props: P) => ReturnType<React.FunctionComponent>,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean,
) => (props: P) => ReturnType<React.FunctionComponent>

interface InputProps<T, V> {
  errorMessage?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  name: keyof PickByType<T, V | null | undefined>
  label?: ReactNode
  error?: boolean
  value?: V
  onChange?: (name: keyof PickByType<T, V | null | undefined>, value: V) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  gridProps?: ComponentProps<typeof Grid>
  startDecorator?: ComponentProps<typeof Input>['startDecorator']
  endDecorator?: ComponentProps<typeof Input>['endDecorator']
  slotProps?: ComponentProps<typeof Input>['slotProps']
}

export function TextInput<T>({
  errorMessage,
  placeholder,
  disabled,
  required,
  error,
  name,
  value = '',
  label = '',
  onChange,
  onFocus,
  gridProps,
  startDecorator,
  endDecorator,
  slotProps,
}: InputProps<T, string>) {
  const textInput = (
    <FormControl error={error || (required && !value)}>
      {disabled ? (
        <FloatingLabel label={label}>
          {/* TODO: Fix FloatingLabelInput disabled styles */}
          <Input
            disabled
            name={name as string}
            value={value}
          />
        </FloatingLabel>
      ) : (
        <FloatingLabelInput
          {...{
            disabled, //
            name: name as string,
            placeholder,
            value,
            onChange: (e) => onChange?.(name, e.target.value),
            label,
            onFocus,
            startDecorator,
            endDecorator,
            slotProps,
          }}
        />
      )}
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
  return !gridProps ? textInput : <Grid {...gridProps}>{textInput}</Grid>
}

interface DateInputProps<T> extends InputProps<T, string> {
  type?: 'date' | 'datetime-local'
}

export function DateInput<T>({
  errorMessage,
  placeholder,
  disabled,
  required,
  error,
  name,
  value,
  label = '',
  type = 'datetime-local',
  onChange,
  gridProps,
}: DateInputProps<T>) {
  const dateInput = (
    <FormControl error={error || (required && !value)}>
      <FloatingLabelDateInput
        {...{
          type,
          disabled, //
          name: name as string,
          placeholder,
          value,
          onChange: ({ target: { value } }) => onChange?.(name, value),
          label,
        }}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )

  return !gridProps ? dateInput : <Grid {...gridProps}>{dateInput}</Grid>
}

interface NumberInputProps<T> extends InputProps<T, number> {
  min?: number
  max?: number
  step?: number
  endDecorator?: ReactNode
}

export function NumberInput<T>({
  endDecorator,
  placeholder,
  disabled,
  required,
  error,
  name,
  step,
  min,
  max,
  value = 0,
  label = '',
  gridProps,
  onChange,
}: NumberInputProps<T>) {
  const numberInput = (
    <FormControl error={error || (required && !value)}>
      {disabled ? (
        <FloatingLabel label={label}>
          {/* TODO: Fix FloatingLabelInput disabled styles */}
          <Input
            disabled
            name={name as string}
            value={value}
          />
        </FloatingLabel>
      ) : (
        <FloatingLabelInput
          type="number"
          {...{
            disabled, //
            name: name as string,
            placeholder,
            value: (value ?? 0).toString(), // To remove leading zeros
            onChange: ({ target: { value } }) => onChange?.(name, Number(value)),
            label,
            min,
            endDecorator,
            max,
            step,
            onFocus: (e) => {
              e.persist()
              !Number(e.target.value) && e.target.select()
            },
          }}
        />
      )}
    </FormControl>
  )
  return !gridProps ? numberInput : <Grid {...gridProps}>{numberInput}</Grid>
}

interface SelectInputProps<T, V> extends InputProps<T, V> {
  options: string[] | { value: V; label: string }[]
  acceptEmpty?: boolean
}

export function SelectInput<T, V extends string | number>({
  placeholder,
  required,
  disabled,
  options,
  error,
  value,
  label,
  name,
  onChange,
  gridProps,
  acceptEmpty,
}: SelectInputProps<T, V>) {
  const selectInput = (
    <FormControl error={error || (required && !value)}>
      <FloatingLabel label={label}>
        <Select
          disabled={disabled}
          name={name as string}
          placeholder={placeholder}
          value={value ?? ('' as V)}
          onChange={(_, value) => onChange?.(name, value!)}
        >
          {!value && (
            <Option
              value={''}
              disabled={!acceptEmpty}
            >
              {placeholder}
            </Option>
          )}
          {options.map((option, index) => (
            <Option
              key={index}
              value={typeof option === 'string' ? option : option.value}
            >
              {typeof option === 'string' ? option : option.label}
            </Option>
          ))}
        </Select>
      </FloatingLabel>
    </FormControl>
  )

  return !gridProps ? selectInput : <Grid {...gridProps}>{selectInput}</Grid>
}

interface TextAreaInputProps<T> extends InputProps<T, string> {
  minRows?: number
  maxRows?: number
}

export function TextAreaInput<T>({
  placeholder,
  disabled,
  required,
  minRows,
  maxRows,
  error,
  name,
  value = '',
  label = '',
  gridProps,
  onChange,
}: TextAreaInputProps<T>) {
  const textAreaInput = (
    <FormControl error={error || (required && !value)}>
      <FloatingLabel label={label}>
        <Textarea
          disabled={disabled}
          placeholder={placeholder}
          name={name as string}
          maxRows={maxRows}
          minRows={minRows}
          value={value}
          onChange={(e) => onChange?.(name, e.target.value)}
        />
      </FloatingLabel>
    </FormControl>
  )

  return !gridProps ? textAreaInput : <Grid {...gridProps}>{textAreaInput}</Grid>
}
interface OptionArrayProps<T> extends InputProps<T, string[]> {
  options: string[]
  requiredErrorMessage?: string
  style?: 'checkbox' | 'switch'
  allowAdd?: boolean
}

export function OptionArray<T>({
  requiredErrorMessage,
  errorMessage,
  disabled,
  allowAdd,
  required,
  name,
  error,
  value = [],
  label = '',
  options,
  style = 'switch',
  onChange,
}: OptionArrayProps<T>) {
  return (
    <OptionArrayBox
      error={error || (required && !value.length)}
      errorMessage={required && !value.length ? requiredErrorMessage : errorMessage}
      label={label}
    >
      <Grid
        container
        spacing={1}
        padding={1}
      >
        {options.map((option, index) => {
          return (
            <OptionArraySwitch
              style={style}
              disabled={disabled}
              key={`${option}-${index}`}
              option={option}
              onChange={(option, checked) =>
                onChange?.(name, checked ? [...value, option] : value.filter((v) => v !== option))
              }
              checked={value?.includes(option)}
            />
          )
        })}
        {value
          ?.filter((option: string) => !options.includes(option))
          .map((option: string, index: number) => (
            <OptionArrayRemovableItem
              key={`${option}-${index}`}
              option={option}
              onRemove={() =>
                onChange?.(
                  name,
                  value.filter((v) => v !== option),
                )
              }
              style={style}
            />
          ))}
        {allowAdd && (
          <OptionAddItem
            style={style}
            onAdd={(newItem) => onChange?.(name, [...value, newItem])}
          />
        )}
      </Grid>
    </OptionArrayBox>
  )
}
interface OptionArrayRemovableItemProps {
  option: string
  style?: 'checkbox' | 'switch'
  onRemove: () => void
}

export function OptionArrayRemovableItem({ option, onRemove, style = 'switch' }: OptionArrayRemovableItemProps) {
  return (
    <Grid
      xs={6}
      sm={4}
      md={style === 'switch' ? 3 : 2}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
      >
        <Tooltip
          placement="top"
          title={option}
          open={option.length < 15 ? false : undefined}
        >
          <Typography
            startDecorator={
              <TrashIcon
                onClick={onRemove}
                style={{ cursor: 'pointer', width: '1em', color: 'var(--joy-palette-primary-plainColor)' }}
              />
            }
          >
            {!!(option.length < 15) ? option : option.slice(0, 14) + '\u2026'}
          </Typography>
        </Tooltip>
      </Stack>
    </Grid>
  )
}

interface OptionArrayBoxProps extends ComponentProps<typeof Box> {
  error?: boolean
  errorMessage?: string
  label?: ReactNode
  labelProps?: React.ComponentProps<typeof Typography>
  gridProps?: ComponentProps<typeof Grid>
}

export const OptionArrayBox = function OptionArrayBox({
  children,
  label,
  error,
  errorMessage,
  labelProps,
  gridProps,
  ...boxProps
}: OptionArrayBoxProps) {
  const optionArrayBox = (
    <FloatingLabel
      label={label}
      {...labelProps}
    >
      <Box
        {...boxProps}
        sx={{ border: globalStyles.border, borderRadius: globalStyles.borderRadius, padding: 1, ...boxProps.sx }}
      >
        {children}
        {error && (
          <FormControl error={error}>
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>
        )}
      </Box>
    </FloatingLabel>
  )

  return !gridProps ? optionArrayBox : <Grid {...gridProps}>{optionArrayBox}</Grid>
}

interface OptionSwitchProps {
  disabled?: boolean
  checked?: boolean
  style?: 'checkbox' | 'switch'
  option: string
  onChange: (option: string, checked: boolean) => void
}

export const OptionArraySwitch = function OptionSwitch({
  option,
  onChange,
  checked,
  disabled,
  style = 'switch',
}: OptionSwitchProps) {
  return (
    <Grid
      xs={6}
      sm={4}
      md={style === 'switch' ? 3 : 2}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {style === 'switch' ? (
        <Typography
          component="label"
          sx={{ cursor: 'pointer' }}
          startDecorator={
            <Switch
              size="sm"
              disabled={disabled}
              checked={Boolean(checked)}
              onChange={(e) => onChange(option, e.target.checked)}
            />
          }
        >
          {option}
        </Typography>
      ) : (
        <Tooltip
          placement="top"
          title={option}
          open={option.length < 16 ? false : undefined}
        >
          <Checkbox
            label={<FormLabel>{option.length < 16 ? option : option.slice(0, 14) + '\u2026'}</FormLabel>}
            checked={checked}
            onChange={(e) => onChange(option, e.target.checked)}
          />
        </Tooltip>
      )}
    </Grid>
  )
}

interface OptionAddItemProps {
  style?: 'checkbox' | 'switch'
  onAdd: (newElement: string) => void
}

function OptionAddItem({ onAdd, style = 'switch' }: OptionAddItemProps) {
  const [extraName, setExtraName] = React.useState('')
  return (
    <Grid
      xs={6}
      sm={4}
      md={style === 'switch' ? 3 : 2}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          my: 0.5,
        }}
      >
        <Input
          placeholder="Ingresar otro"
          value={extraName}
          onChange={(e) => setExtraName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') return
            onAdd(extraName)
            setExtraName(() => '')
          }}
        />
        <Tooltip
          placement="top"
          title="Agregar elemento"
        >
          <AddCircleOutline
            sx={{ cursor: 'pointer', color: 'var(--joy-palette-primary-plainColor)' }}
            onClick={() => {
              onAdd(extraName)
              setExtraName(() => '')
            }}
          />
        </Tooltip>
      </Stack>
    </Grid>
  )
}

const handlePlacesAutoComplete = (address: google.maps.places.PlaceResult[] | undefined) => {
  enum GeoType {
    COUNTRY = 'country',
    STATE = 'administrative_area_level_1',
    PARTY = 'administrative_area_level_2',
    CITY = 'locality',
    SUBLOCALITY = 'sublocality',
    NEIGHBORHOOD = 'neighborhood',
    STREET = 'route',
    STREET_NUMBER = 'street_number',
    INTERSECTION = 'intersection',
  }
  let street = ''
  let streetNumber = ''
  let state = ''
  let party = ''
  let city = ''
  let sublocality = ''
  let country = ''
  let neighborhood = ''
  let geo_lat = address?.[0]?.geometry?.location?.lat()
  let geo_long = address?.[0]?.geometry?.location?.lng()
  let intersection = ''

  address?.[0]?.address_components?.forEach((element) => {
    if (element.types.includes(GeoType.STATE)) {
      state = element.short_name
    }
    if (element.types.includes(GeoType.CITY)) {
      city = element.long_name
    }
    if (element.types.includes(GeoType.COUNTRY)) {
      country = element.long_name
    }
    if (element.types.includes(GeoType.STREET)) {
      street = element.long_name
    }
    if (element.types.includes(GeoType.STREET_NUMBER)) {
      streetNumber = element.long_name
    }
    if (element.types.includes(GeoType.NEIGHBORHOOD)) {
      neighborhood = element.long_name
    }
    if (element.types.includes(GeoType.PARTY)) {
      party = element.long_name
    }
    if (element.types.includes(GeoType.SUBLOCALITY)) {
      sublocality = element.long_name
    }
    if (element.types.includes(GeoType.INTERSECTION)) {
      intersection = element.long_name.replace('&', 'y')
    }
  })

  return {
    real_address: cleanString(`${street} ${streetNumber}`.trim() || intersection),
    country: cleanString(country),
    city: cleanString(party),
    neighborhood: cleanString(
      Boolean(sublocality) || Boolean(neighborhood)
        ? `${sublocality} - ${neighborhood}`.trim().replace(/-$/, '').replace(/^-/, '').trim()
        : city,
    ),
    state: cleanString(state),
    geo_lat,
    geo_long,
  }
}

interface PlacesInputProps {
  onAutoComplete?: (adress: {
    real_address: string
    country: string
    city: string
    neighborhood: string
    state: string
    geo_lat: number | undefined
    geo_long: number | undefined
  }) => void
  setValue: (value: string) => void
  value?: string
  size?: 'sm'
  disabled?: boolean
}

export const PlacesInput = ({ onAutoComplete, setValue, value, size, disabled }: PlacesInputProps) => {
  const [autocomplete, setAutocomplete] = useState(false)
  const googleSearchBoxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autocomplete) googleSearchBoxRef.current!.focus()
  }, [autocomplete])

  const button = !size ? (
    <Button
      disabled={autocomplete || disabled}
      onClick={() => setAutocomplete(true)}
      endDecorator={<Add />}
      sx={{ height: globalStyles.inputHeight }}
    >
      Autocompletar
    </Button>
  ) : (
    <IconButton
      disabled={autocomplete || disabled}
      onClick={() => setAutocomplete(true)}
      // sx={{ height: globalStyles.inputHeight }}
    >
      <Tooltip title="Autocompletar">
        <Box>
          <GoogleIcon />
        </Box>
      </Tooltip>
    </IconButton>
  )

  return (
    <Grid md={size === 'sm' ? 12 : 6}>
      <Stack
        direction="row"
        gap={2}
      >
        <div style={{ display: autocomplete ? 'block' : 'none', width: !size ? '66%' : '100%' }}>
          <GoogleSearchBox
            handleAutoComplete={(places) => onAutoComplete?.(handlePlacesAutoComplete(places))}
            sbInputValue={realAddressComponents(value)?.address}
            onBlur={() => setAutocomplete(false)}
            googleSearchBoxRef={googleSearchBoxRef}
            button={size === 'sm' && button}
          />
        </div>
        {!autocomplete && (
          <div style={{ width: !size ? '66%' : '100%' }}>
            {
              <FloatingLabel label={'Dirección'}>
                <Input
                  name={'real_address'}
                  required
                  onChange={(e) => setValue(e.target.value)}
                  value={value ?? ''}
                  endDecorator={size === 'sm' && button}
                  disabled={disabled}
                />
              </FloatingLabel>
            }
          </div>
        )}
        {!size && button}
      </Stack>
    </Grid>
  )
}

const GoogleSearchBox = ({
  handleAutoComplete,
  onBlur,
  sbInputValue = '',
  googleSearchBoxRef,
  button,
}: {
  handleAutoComplete?: (places: google.maps.places.PlaceResult[] | undefined) => void
  onBlur?: () => void
  sbInputValue?: string
  googleSearchBoxRef?: React.RefObject<HTMLInputElement>
  button?: ReactNode
}) => {
  // const map = useMap('location-map')

  const placesLibrary = useMapsLibrary('places')

  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null)

  useEffect(() => {
    if (!placesLibrary || !googleSearchBoxRef) return
    setSearchBox(new placesLibrary.SearchBox(googleSearchBoxRef.current!))
  }, [placesLibrary])
  // }, [placesLibrary, map])

  useEffect(() => {
    if (!searchBox) return
    searchBox.addListener('places_changed', () => {
      handleAutoComplete?.(searchBox.getPlaces())
    })
  }, [searchBox])

  const [inputValue, setInputValue] = useState(sbInputValue)

  useEffect(() => {
    setInputValue(sbInputValue)
  }, [sbInputValue])

  return (
    <FloatingLabelInput
      endDecorator={button}
      label="Dirección"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={() => {
        setInputValue(sbInputValue)
        onBlur?.()
      }}
      innerRef={googleSearchBoxRef}
    />
  )
}

interface MapInputProps {
  center: google.maps.LatLngLiteral
  reference_points?: { lat: number; lng: number; name?: string }[]
  onClick?: (lat: number, lng: number) => void
  onDragMarker?: (lat: number, lng: number) => void
  mapName: string
  gridProps?: ComponentProps<typeof Grid>
}

export const MapInput = ({
  center,
  reference_points = [],
  onClick,
  onDragMarker,
  mapName,
  gridProps,
}: MapInputProps) => {
  const map = useMap(mapName)

  useEffect(() => {
    if (!map) return
    const styledMap = new google.maps.StyledMapType(mapStyles)
    map.setClickableIcons(false)
    map.mapTypes.set('setMapStyles', styledMap)
    map.setMapTypeId('setMapStyles')
    map.setOptions({
      draggableCursor: 'pointer',
      scrollwheel: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
    })
  }, [map, center])

  useEffect(() => {
    if (!map) return
    map.setCenter(center)
  }, [center.lat, center.lng])

  const [isDragging, setIsDragging] = useState(false)

  const mapInput = (
    <div
      style={{
        width: '100%',
        height: `30vh`,
      }}
    >
      <Map
        onClick={(e) => {
          if (isDragging) return
          if (reference_points.length >= 10) return
          const lat = e.detail.latLng?.lat
          const lng = e.detail.latLng?.lng
          onClick?.(lat!, lng!) // Why a map click event is not guaranteed to have a lat and lng?
        }}
        defaultCenter={center}
        defaultZoom={14}
        mapId={mapName}
        id={mapName}
      >
        <AdvancedMarker
          draggable={Boolean(onDragMarker)}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={(e) => {
            const lat = e.latLng?.lat()
            const lng = e.latLng?.lng()
            onDragMarker?.(lat!, lng!) // Why a map drag event is not guaranteed to have a lat and lng?
            setTimeout(() => setIsDragging(false), 100) // To avoid the click event to be triggered after dragging
          }}
          position={center}
        >
          <Pin glyphColor={'#f5f5f5'} />
        </AdvancedMarker>
        {reference_points?.map(({ lat, lng }, index: number) => (
          <AdvancedMarker
            key={`marker-${index}`}
            position={{ lat, lng }}
          >
            <Pin
              background={chartsColors[index]}
              borderColor={chartsColors[index]}
              glyphColor={'#f5f5f5'}
            />
          </AdvancedMarker>
        ))}
      </Map>
    </div>
  )

  return !gridProps ? mapInput : <Grid {...gridProps}>{mapInput}</Grid>
}

interface AuthorSelectProps {
  value?: string
  onChange?: (value: string) => void
}

export const AuthorSelect = ({ value, onChange }: AuthorSelectProps) => {
  const { user } = useSession()
  const userName = `${user?.first_name} ${user?.last_name}`.trim()

  const [renders, addRender] = useReducer((renders) => renders + 1, 0)

  const [selectValue, setSelectValue] = useState<'user' | 'void' | 'other'>('void')
  useEffect(() => {
    if (renders > 2) return // Selects "user" only on the first data render
    addRender()
    if (value) setSelectValue(userName && userName === value ? 'user' : 'other')
  }, [userName, value])

  return (
    <>
      <Grid
        xs={12}
        md={6}
      >
        <FloatingLabel label="Autor">
          <Select
            name="author"
            value={selectValue}
            onChange={(_, value) => {
              setSelectValue(value!)
              onChange?.(value === 'user' ? userName : '')
            }}
          >
            <Option value="void">Sin especificar</Option>
            {userName && <Option value="user">{userName}</Option>}
            <Option value="other">Otro</Option>
          </Select>
        </FloatingLabel>
      </Grid>
      <Grid
        xs={12}
        md={6}
      >
        {selectValue === 'other' && (
          <FloatingLabelInput
            name="author"
            label="Nombre del autor"
            placeholder="Ingresá el nombre del autor"
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
          />
        )}
      </Grid>
    </>
  )
}

export const ModuleSelect = ({
  value,
  onChange,
  gridProps,
  label,
}: {
  value?: string
  onChange?: (value: string) => void
  gridProps?: ComponentProps<typeof Grid>
  label?: string
}) => {
  const { user } = useSession()
  const { data: modules } = useGetModulesQuery(undefined, { skip: !user })
  const moduleSelect = (
    <SelectInput<any, string>
      name="module"
      label={label ?? 'Módulo'}
      value={value}
      onChange={(_, value) => onChange?.(value)}
      acceptEmpty
      placeholder="Ninguno"
      options={
        modules?.map((module) => ({
          value: module.code,
          label: module.title,
        })) ?? []
      }
    />
  )
  return !gridProps ? moduleSelect : <Grid {...gridProps}>{moduleSelect}</Grid>
}
