import React, { useEffect, useState } from 'react'
import { DynamicTable, TextCell, sortableNumberCell, sortablePlainTextCell } from '@components/tables/DynamicTable'
import { AddNewButton } from '@components/AddNewButton'
import { routes, support_ticket_priority_colors, support_ticket_status_colors } from '@constants'
import { SupportTicket } from '@types'
import moment from 'moment'
import { Badge, Drawer, IconButton, Tooltip } from '@mui/joy'
import SupportTicketForm from '@components/Forms/SupportTicketForm'
import { useGetTicketTableQuery } from '@endpoints/tablesEndpoint'
import { useSession } from '@hooks/useSession'
import { globalStyles } from '@styles/styles'
import { Circle } from '@mui/icons-material'
import { Rating } from '@mui/material'
import { EyeIcon } from '@heroicons/react/24/outline'
import { FlagIcon } from '@heroicons/react/24/solid'
import { isForbidden } from '@utils'
import { useTableConfig } from '@hooks/useTableConfig'

interface DrawerState {
  open: boolean
  id?: number
}
interface Props {
  defaultDrawerState?: DrawerState
  isTableDrawerOpen?: boolean
}

export const SupportTicketsTable = ({ defaultDrawerState, isTableDrawerOpen }: Props) => {
  const { userLevel, client, hasTicketsCreatePermission } = useSession()
  const [drawer, setDrawer] = useState<DrawerState>(defaultDrawerState ?? { open: false })

  useEffect(() => {
    if (isTableDrawerOpen) {
      window.history.replaceState(
        null,
        '',
        !drawer.open
          ? routes.core.support_tickets
          : !drawer.id
          ? routes.core.add_support_ticket
          : routes.core.edit_support_ticket(drawer.id),
      )
    }
  }, [drawer, isTableDrawerOpen])

  const {
    data: allTickets,
    isLoading: isLoadingTickets,
    isFetching: isFetchingTickets,
    isError: isErrorTicket,
    error: errorTicket,
  } = useGetTicketTableQuery(
    { clientId: client?.id!, ...useTableConfig({ uniqueId: 'supportTickets' }) },
    { skip: !client?.id },
  )

  const isSuperAdmin = userLevel('ADMIN')

  const getPriorityColor = (priority: SupportTicket['priority']) => support_ticket_priority_colors[priority]
  const getStatusColor = (status: SupportTicket['status']) => support_ticket_status_colors[status]

  return (
    <>
      <DynamicTable
        useBackendFilter
        meta={allTickets?.meta}
        uniqueId="supportTickets"
        isLoading={isLoadingTickets}
        error={isErrorTicket && !isForbidden(errorTicket)}
        forbidden={isForbidden(errorTicket)}
        options={{
          activeTabName: 'Abierto',
          inactiveTabName: 'Cerrado',
          hiddenTabs: true,
          customBodyHeight: `calc(100vh - ${
            globalStyles.stepTitleHeight + globalStyles.navBarHeight + globalStyles.topBarHeight
          }px)`,
        }}
        extraElements={
          !isSuperAdmin ? (
            <AddNewButton
              disabled={!hasTicketsCreatePermission}
              onClick={() => setDrawer({ open: true })}
            />
          ) : (
            <></>
          )
        }
        fetching={isFetchingTickets}
        tableConfig={{
          id: { header: '#', sortType: 'number', width: '40px' },
          status: { header: 'Estado', width: '90px' },
          priority: { header: 'Prioridad', width: '80px' },
          ...(isSuperAdmin ? { client: { header: 'Cliente', sortType: 'string', width: '70px' } } : {}),
          subject: { header: 'Asunto', sortType: 'string' },
          created_at: { header: 'Fecha', sortType: 'date', width: '70px' },
          // ...(isSuperAdmin ? { rating: { header: 'Calificación' } } : {}),
          actions: { header: 'Acciones' },
        }}
        rows={
          allTickets?.results.map((supportTicket) => ({
            activeRow: supportTicket.status !== 'Cerrado',
            content: {
              id: sortableNumberCell(supportTicket.id),
              status: {
                render: (
                  <td>
                    <Circle sx={{ color: getStatusColor(supportTicket.status), fontSize: '1rem', mr: 1 }} />
                    {supportTicket.status ?? '-'}
                  </td>
                ),
                sortValue: supportTicket?.status ?? '',
              },
              priority: {
                render: (
                  <td>
                    <FlagIcon
                      style={{
                        color: getPriorityColor(supportTicket?.priority ?? ''),
                        width: '.5em',
                        marginRight: '.2em',
                      }}
                    />

                    {supportTicket?.priority ?? '-'}
                  </td>
                ),
                sortValue: supportTicket?.priority ?? '',
              },
              client: sortablePlainTextCell((supportTicket?.client as number).toString(), 20),
              area: sortablePlainTextCell(supportTicket?.area, 20),
              subject: sortablePlainTextCell(supportTicket?.subject, 20),
              created_at: sortablePlainTextCell(supportTicket?.created_at),
              resolution_date: {
                render: (
                  <TextCell
                    cellStyles={{
                      color:
                        moment(supportTicket?.resolution_date).isBefore(moment(), 'day') &&
                        supportTicket.status !== 'Cerrado'
                          ? '#FF0000'
                          : '#000000',
                    }}
                  >
                    {supportTicket?.resolution_date ? moment(supportTicket.resolution_date).format('DD/MM/YYYY') : '-'}
                  </TextCell>
                ),
                sortValue: supportTicket?.resolution_date,
              },
              rating: {
                render: (
                  <td>
                    <Rating
                      value={supportTicket.rating}
                      readOnly
                    />
                  </td>
                ),
              },
              actions: {
                render: (
                  <td style={{ textAlign: 'center' }}>
                    <Tooltip title="Abrir ticket">
                      <IconButton
                        variant="plain"
                        onClick={() => setDrawer({ open: true, id: supportTicket.id })}
                      >
                        <Badge
                          size="sm"
                          color="danger"
                          invisible={
                            !supportTicket.messages?.some((message) => {
                              return (
                                message.read === false &&
                                (isSuperAdmin ? message.role === 'client' : message.role === 'admin')
                              )
                            })
                          }
                        >
                          <EyeIcon style={{ width: '1em' }} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </td>
                ),
              },
            },
          })) ?? []
        }
      />
      <Drawer
        anchor="right"
        open={drawer.open}
        onClose={() => setDrawer({ open: false })}
        slotProps={globalStyles.formDrawerSlotProps}
      >
        {Boolean(client?.id) && drawer.open && (
          <SupportTicketForm
            key={drawer.id}
            clientId={client?.id!}
            id={drawer.id}
            onClose={() => setDrawer({ open: false })}
          />
        )}
      </Drawer>
    </>
  )
}
