import {
  useUploadAppraisalBlueprintsMutation,
  useUploadAppraisalFilesMutation,
  useUploadAppraisalImagesMutation,
} from '@endpoints/appraisalsEndpoint'
import {
  useUploadSiteDevelopmentBlueprintsMutation,
  useUploadSiteDevelopmentFilesMutation,
  useUploadSiteDevelopmentImagesMutation,
} from '@endpoints/developmentsEndpoint'
import { useUploadInvestorNewsFilesMutation, useUploadInvestorNewsImagesMutation } from '@endpoints/investorEndpoint'
import { useUploadNewsFilesMutation, useUploadNewsImagesMutation } from '@endpoints/newsEndpoint'
import { useUploadOwnerNewsFilesMutation } from '@endpoints/ownersEndpoint'
import {
  useUploadPropertyBlueprintsMutation,
  useUploadPropertyFilesMutation,
  useUploadPropertyImagesMutation,
} from '@endpoints/propertiesEndpoint'
import { useUploadResourceFilesMutation } from '@endpoints/resourcesEndpoint'
import { Id, toast } from 'react-toastify'

export const useGallery = () => {
  interface GalleryData {
    files: File[]
    file_name: string
    text_alt: string
    order?: number
    public_name?: string
    is_active?: boolean
    created_at?: string
  }

  interface GalleryArgs {
    id: number
    gallery?: Image[]
    pendingText: string
    successText: string
    errorText: string
    galleryMutation: (args: { id: number; data: GalleryData }) => any
  }

  interface Image {
    file: File
    text_alt?: string
    order?: number
    public_name?: string
    is_active?: boolean
    created_at?: string
  }

  const galleryFn = async ({ id, gallery, pendingText, successText, errorText, galleryMutation }: GalleryArgs) => {
    if (!gallery) return
    let imagesUploadIndex = 1
    let uploadErrors = 0

    const uploadImage = async (image: Image, id: number, toastId: Id) =>
      galleryMutation({
        id,
        data: {
          files: [image.file],
          file_name: image.file.name, //
          text_alt: image.text_alt ?? '',
          order: image.order,
          public_name: image.public_name,
          is_active: image.is_active,
          created_at: image.created_at,
        },
      })
        .unwrap()
        .catch(() => uploadErrors++)
        .finally(() => {
          toast.update(toastId, {
            render: `${pendingText} ${imagesUploadIndex++} de ${gallery.length} ...`,
          })
        })

    const toastId = toast.loading(`${pendingText} 1 de ${gallery.length} ...`)

    Promise.allSettled(gallery.map((image) => uploadImage(image, id, toastId)))
      .then(() =>
        toast.update(toastId, {
          render:
            uploadErrors === gallery.length
              ? errorText
              : `${successText}: ${gallery.length - uploadErrors} de ${gallery.length}`,
          type: !uploadErrors ? 'success' : uploadErrors === gallery.length ? 'error' : 'warning',
          isLoading: false,
          autoClose: 3000,
        }),
      )
      .catch(() => {})
  }

  const [uploadPropertyImageMutation] = useUploadPropertyImagesMutation()
  const [uploadPropertyBlueprintMutation] = useUploadPropertyBlueprintsMutation()
  const [uploadPropertyFileMutation] = useUploadPropertyFilesMutation()
  const [uploadDevelopmentImageMutation] = useUploadSiteDevelopmentImagesMutation()
  const [uploadDevelopmentBlueprintMutation] = useUploadSiteDevelopmentBlueprintsMutation()
  const [uploadDevelopmentFileMutation] = useUploadSiteDevelopmentFilesMutation()
  const [uploadNewsImagesMutation] = useUploadNewsImagesMutation()
  const [uploadNewsFilesMutation] = useUploadNewsFilesMutation()
  const [uploadInvestorNewsImagesMutation] = useUploadInvestorNewsImagesMutation()
  const [uploadInvestorNewsFilesMutation] = useUploadInvestorNewsFilesMutation()
  const [uploadAppraisalImageMutation] = useUploadAppraisalImagesMutation()
  const [uploadAppraisalBlueprintMutation] = useUploadAppraisalBlueprintsMutation()
  const [uploadAppraisalFilesMutation] = useUploadAppraisalFilesMutation()
  const [uploadOwnerNewsFilesMutation] = useUploadOwnerNewsFilesMutation()
  const [uploadResourcesFilesMutation] = useUploadResourceFilesMutation()

  interface UploadArgs {
    id: number
    gallery: Image[]
  }

  const genericImageMessages = {
    pendingText: 'Cargando imagen',
    successText: 'Imagenes cargadas con éxito',
    errorText: 'No se pudieron cargar las imagenes',
  }

  const genericBlueprintMessages = {
    pendingText: 'Cargando plano',
    successText: 'Planos cargados con éxito',
    errorText: 'No se pudieron cargar los planos',
  }

  const genericFileMessages = {
    pendingText: 'Cargando archivo',
    successText: 'Archivos cargados con éxito',
    errorText: 'No se pudieron cargar los archivos',
  }

  const uploadPropertyImages = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericImageMessages,
      galleryMutation: uploadPropertyImageMutation,
    })

  const uploadPropertyBlueprints = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericBlueprintMessages,
      galleryMutation: uploadPropertyBlueprintMutation,
    })

  const uploadPropertyFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadPropertyFileMutation,
    })

  const uploadDevelopmentImages = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericImageMessages,
      galleryMutation: uploadDevelopmentImageMutation,
    })

  const uploadDevelopmentBlueprints = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericBlueprintMessages,
      galleryMutation: uploadDevelopmentBlueprintMutation,
    })

  const uploadDevelopmentFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadDevelopmentFileMutation,
    })

  const uploadNewsImages = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericImageMessages,
      galleryMutation: uploadNewsImagesMutation,
    })

  const uploadNewsFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadNewsFilesMutation,
    })

  const uploadInvestorNewsImages = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericImageMessages,
      galleryMutation: uploadInvestorNewsImagesMutation,
    })

  const uploadInvestorNewsFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadInvestorNewsFilesMutation,
    })

  const uploadAppraisalImages = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericImageMessages,
      galleryMutation: uploadAppraisalImageMutation,
    })

  const uploadAppraisalBlueprints = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericBlueprintMessages,
      galleryMutation: uploadAppraisalBlueprintMutation,
    })

  const uploadAppraisalFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadAppraisalFilesMutation,
    })

  const uploadOwnerNewsFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadOwnerNewsFilesMutation,
    })

  const uploadResourceFiles = async ({ id, gallery }: UploadArgs) =>
    galleryFn({
      id,
      gallery,
      ...genericFileMessages,
      galleryMutation: uploadResourcesFilesMutation,
    })

  return {
    uploadPropertyImages,
    uploadPropertyBlueprints,
    uploadPropertyFiles,
    uploadDevelopmentImages,
    uploadDevelopmentBlueprints,
    uploadDevelopmentFiles,
    uploadNewsImages,
    uploadNewsFiles,
    uploadInvestorNewsImages,
    uploadInvestorNewsFiles,
    uploadAppraisalImages,
    uploadAppraisalBlueprints,
    uploadAppraisalFiles,
    uploadOwnerNewsFiles,
    uploadResourceFiles
  }
}
