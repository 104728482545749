import {
  useCreateTextTemplateMutation,
  useDeleteTextTemplateMutation,
  useGetTextTemplatesQuery,
  useUpdateTextTemplateMutation,
} from '@endpoints/textTemplatesEndpoint'
import { TextTemplate, ValidationErrors } from '@types'
import { useState } from 'react'
import { useSession } from '@hooks/useSession'

export const useTextTemplates = () => {
  const { user, client } = useSession()

  const getTextTemplates = ({ section }: { section?: string; title?: string }) => {
    const {
      data: allTextTemplates,
      isLoading: isLoadingTextTemplates,
      isError: isErrorTextTemplates,
    } = useGetTextTemplatesQuery({ user: user?.id, section }, { skip: !client })

    return {
      allTextTemplates,
      isLoadingTextTemplates,
      isErrorTextTemplates,
    }
  }

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

  const [createTextTemplate, { isLoading: isCreatingTextTemplate }] = useCreateTextTemplateMutation()
  const [updateTextTemplate, { isLoading: isUpdatingTextTemplate }] = useUpdateTextTemplateMutation()
  const [deleteTextTemplate, { isLoading: isDeletingTextTemplate }] = useDeleteTextTemplateMutation()

  const saveTextTemplate = ({
    data,
    id,
  }: {
    data: Partial<TextTemplate>
    id?: number
  }): Promise<void | TextTemplate> => {
    return new Promise((resolve, reject) => {
      if (data.title === '') {
        setValidationErrors({ title: 'El título es requerido' })
        return reject()
      }
      return (id ? updateTextTemplate({ id, data }) : createTextTemplate({ data }))
        .unwrap()
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          if (error.status === 400 && error.data?.non_field_errors?.length) {
            setValidationErrors({ title: 'Ya existe un texto con ese título' })
          }
        })
    })
  }

  return {
    saveTextTemplate,
    isCreatingTextTemplate,
    isUpdatingTextTemplate,
    isDeletingTextTemplate,
    deleteTextTemplate,
    validationErrors,
    getTextTemplates,
  }
}
