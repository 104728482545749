import { mediacoreApi } from '@apis/mediacoreApi'
import { createOneMutation, deleteByIdMutation, getByIdQuery, updateByIdMutation } from '@apis/mediacoreQueries'
import { TextTemplate } from '@types'

const texTemplatesApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getTextTemplates: builder.query<TextTemplate[], { user?: number; section?: string; title?: string }>({
      query: ({ user, section, title }) => ({
        url: 'core/plantillas-textos/',
        method: 'GET',
        params: { user, section, title },
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'TextTemplate' as const, id })), 'TextTemplate']
          : ['TextTemplate'],
    }),
    getTextTemplateById: builder.query<TextTemplate, { id: number }>({
      query: getByIdQuery('core/plantillas-textos'),
      providesTags: (_result, _error, { id }) => [{ type: 'TextTemplate', id }],
    }),
    deleteTextTemplate: builder.mutation<void, { id: number }>({
      query: deleteByIdMutation('core/plantillas-textos'),
      invalidatesTags: ['TextTemplate'],
    }),
    createTextTemplate: builder.mutation<TextTemplate, { data: Partial<TextTemplate> }>({
      query: createOneMutation('core/plantillas-textos'),
      invalidatesTags: ['TextTemplate'],
    }),
    updateTextTemplate: builder.mutation<TextTemplate, { id: number; data: Partial<TextTemplate> }>({
      query: updateByIdMutation('core/plantillas-textos'),
      invalidatesTags: ['TextTemplate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTextTemplatesQuery,
  useGetTextTemplateByIdQuery,
  useDeleteTextTemplateMutation,
  useCreateTextTemplateMutation,
  useUpdateTextTemplateMutation,
  usePrefetch: usePrefetchTextTemplates,
} = texTemplatesApi
