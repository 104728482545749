import {
  useCreateSupportTicketMutation,
  useGetSupportTicketByIdQuery,
  useUpdateSupportTicketMutation,
} from '@endpoints/supportTicketsEndpoint'
import { auth } from '@firebaseUtils/firebase'
import { SupportTicket, Tenant, User } from '@types'
import { pendingToast, removeKeyFromArray, updateErrorToast, updateSuccessToast } from '@utils'
import moment from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSession } from './useSession'

export interface Props {
  id?: number
  clientId: number
}

interface ImageGallery {
  id?: number
  file?: File | string
  text_alt?: string
  message_id?: number
}

export const useSupportTicket = ({ id, clientId }: Props) => {
  const { user, userLevel } = useSession()

  const [updateSupportTicket, { isLoading: isUpdating }] = useUpdateSupportTicketMutation()
  const [createSupportTicket, { isLoading: isCreating }] = useCreateSupportTicketMutation()

  const {
    data: queriedSupportTicket,
    isLoading: isLoadingTicket,
    isError: isErrorTicket,
  } = useGetSupportTicketByIdQuery({ id: id!, clientId }, { skip: !clientId || !id })

  const blankSupportTicket: Partial<SupportTicket> = {
    area: 'Técnico',
    priority: 'Baja',
    subject: '',
    status: 'No iniciado',
    resolution_date: '',
    rating: 0,
    messages: [
      {
        id: 0,
        user: user?.email ?? '',
        role: 'client',
        content: '',
        created_at: moment().format('D/M/YYYY, HH:mm:ss'),
        read: false,
        avatar: auth.currentUser?.photoURL ?? '',
      },
    ],
    extra_info: { visualizedSteps: [0] },
  }
  const isTicketClosed = queriedSupportTicket?.status === 'Cerrado'

  const isBusy = isUpdating || isCreating || isLoadingTicket

  const [supportTicket, setSupportTicket] = useState<Partial<SupportTicket>>(blankSupportTicket)

  const [files, setFiles] = useState<File[] | null>()

  const [messageCreated, setMessageCreated] = useState(false)

  useEffect(() => {
    if (queriedSupportTicket) {
      const updatedMessages = queriedSupportTicket.messages.map((message) => ({
        ...message,
        read: (isSuperAdmin && message.role === 'client') || (isClientAdmin && message.role === 'admin') || true,
      }))
      setSupportTicket({ ...queriedSupportTicket, messages: updatedMessages } ?? blankSupportTicket)

      if (!messageCreated) {
        createNewMessage(Math.max(0, ...(queriedSupportTicket.messages?.map((message) => message.id) || [])))
        setMessageCreated(true)
      }
    }
  }, [queriedSupportTicket])

  const isSuperAdmin = userLevel('ADMIN')

  const isClientAdmin = userLevel('CLIENT') && !isSuperAdmin
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setSupportTicket((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleMessageContentChange = (e: ChangeEvent<HTMLInputElement>, message_id: number) => {
    const { name, value } = e.target
    setSupportTicket((prevData) => {
      const updatedMessages = prevData?.messages?.map((message) =>
        message.id === message_id ? { ...message, [name]: value } : message,
      )
      return { ...prevData, messages: updatedMessages }
    })
  }

  const messagesToShow = queriedSupportTicket?.messages

  const createNewMessage = (message_id: number) => {
    if (queriedSupportTicket) {
      const { area } = queriedSupportTicket
      setSupportTicket((prevData) => ({
        ...prevData,
        messages: [
          ...(prevData.messages ?? []),
          {
            id: message_id + 1,
            user: isSuperAdmin
              ? `${user?.first_name ?? ''} ${user?.last_name ?? ''} - ${
                  area === 'Administración' ? 'Equipo de' : 'Equipo'
                } ${area}`
              : user?.email ?? '',
            role: isSuperAdmin ? 'admin' : 'client',
            content: '',
            created_at: moment().format('D/M/YYYY, HH:mm:ss'),
            read: false,
            avatar: auth.currentUser?.photoURL ?? '',
          },
        ],
      }))
    }
  }

  const lastMessageId = Math.max(0, ...(supportTicket.messages?.map((message) => message.id) || [])) ?? 0

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSupportTicket((prevData) => ({
      ...prevData,
      resolution_date: moment(value).format(),
    }))
  }

  const handleFileInputChange = (newFiles: File[] | null, message_id: number) => {
    setFiles((prevFiles) => [...(prevFiles ?? []), ...(newFiles ?? [])])
    setSupportTicket((prevData) => ({
      ...prevData,
      files_data: [
        ...(prevData?.files_data ?? []),
        ...(newFiles?.map((nf) => ({ file_name: nf.name, message_id })) ?? []),
      ],
    }))
  }

  const deleteFile = (index: number) => {
    setSupportTicket((prevData) => {
      let newFiles = [...(prevData?.files_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        files_data: newFiles,
      }
    })
  }

  const stepsCompleted =
    !!supportTicket.area && !!supportTicket.subject && !!supportTicket.messages && !!supportTicket.messages[0].content

  const markVisualizedStep = (step: number) => {
    if (!supportTicket?.extra_info?.visualizedSteps?.includes(step))
      setSupportTicket((prevData) => ({
        ...prevData,
        extra_info: {
          ...prevData.extra_info,
          visualizedSteps: [...(prevData?.extra_info?.visualizedSteps ?? []), step],
        },
      }))
  }

  const handleChangeGalleryFiles = (filteredFiles: File[] | undefined, messageId?: number) =>
    setSupportTicket((prevData) => ({
      ...prevData,
      images_data: [
        ...(prevData?.images_data ?? []),
        ...(filteredFiles?.map((newFile, fileIndex) => ({
          file: newFile,
          text_alt: '',
          order: (prevData?.images_data?.length ?? 0) + fileIndex,
          message_id: messageId,
        })) ?? []),
      ],
    }))

  const handleReorderGallery = (e: React.DragEvent<HTMLElement>, index: number) =>
    setSupportTicket((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      const reOrder = (array: Array<any>, from: number, to: number) => array.splice(to, 0, array.splice(from, 1)[0])

      reOrder(newFiles, Number(e.dataTransfer.getData('index')), index)
      return {
        ...prevData,
        images_data: newFiles.map((file, fileIndex) => ({ ...file, order: fileIndex })),
      }
    })

  const deleteGalleryImage = (index: number) =>
    setSupportTicket((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        images_data: newFiles,
      }
    })

  const handleGalleryAltChange = (e: ChangeEvent<HTMLInputElement>, index: number) =>
    setSupportTicket((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      newFiles[index] = { ...newFiles[index], text_alt: e.target.value }
      return {
        ...prevData,
        images_data: newFiles,
      }
    })

  const removeKey = (object: any, key: string) => {
    let newObject = { ...object }
    delete newObject[key as keyof typeof newObject]
    return newObject
  }

  const uploadGallery = (result: SupportTicket, gallery: ImageGallery[], index: number, length: number) => {
    toast.promise(
      updateSupportTicket({
        id: result.id!,
        data: {
          ...removeKey(result, 'image'),
          client: (result.client as Tenant).id,
          user: (result.user as User).id,
          resolution_date: result.resolution_date ?? '',
          files: [gallery[0].file as File],
          files_data: removeKeyFromArray(result.files_data ?? [], 'file'),
          images_data: [
            ...removeKeyFromArray(result.images_data ?? [], 'file'),
            {
              file_name: (gallery[0].file as File).name,
              text_alt: gallery[0].text_alt,
              message_id: gallery[0].message_id,
            },
          ],
        },
      })
        .unwrap()
        .then((successResult) => {
          let nextGallery = gallery
          nextGallery.shift()
          if (nextGallery.length) uploadGallery(successResult, nextGallery, index + 1, length)
        })
        .catch(() => {
          let nextGallery = gallery
          nextGallery.shift()
          if (nextGallery.length) uploadGallery(result, nextGallery, index + 1, length)
        }),
      {
        pending: `Guardando imagen ${index} de ${length} ...`,
        success: `Imagen ${index} de ${length} guardada con éxito`,
        error: `No se pudo guardar la imagen "${index} de ${length} `,
      },
    )
  }

  const saveSupportTicket = () => {
    return new Promise((resolve, reject) => {
      const filteredMessages = supportTicket.messages?.filter(
        (message) =>
          message.content.length > 0 ||
          supportTicket.images_data?.some((image) => image.message_id === message.id) ||
          supportTicket.files_data?.some((file) => file.message_id === message.id),
      )
      const data = {
        ...supportTicket,
        client: id ? (supportTicket?.client as Tenant)?.id : clientId,
        user: id ? (supportTicket?.user as User)?.id : user?.id,
        files_data: removeKeyFromArray(supportTicket?.files_data ?? [], 'file'),
        resolution_date: supportTicket?.resolution_date ?? '',
        messages: filteredMessages,
      }

      if (files) {
        Object.assign(data, {
          files: files,
        })
      }

      const toastId = pendingToast(toast, `${id ? 'Guardando' : 'Creando'} ticket de soporte`)
      ;(id
        ? updateSupportTicket({
            id,
            data: {
              ...data,
              images_data: removeKeyFromArray(
                data.images_data?.filter((id) => typeof id.file === 'string') ?? [],
                'file',
              ),
            },
          })
        : createSupportTicket({
            data: {
              ...data,
              images_data: removeKeyFromArray(
                data.images_data?.filter((id) => typeof id.file === 'string') ?? [],
                'file',
              ),
            },
          })
      )
        .unwrap()
        .then((result) => {
          const imagesToUpload = supportTicket?.images_data?.filter((id) => id.file instanceof File)
          if (imagesToUpload?.length) {
            uploadGallery(result, imagesToUpload, 1, imagesToUpload.length)
          }
          updateSuccessToast(toast, toastId, `Ticket de soporte ${id ? 'editado' : 'creado'} con éxito`)
          resolve(result)
        })
        .catch((error) => {
          updateErrorToast(
            toast,
            toastId,
            error.status === 403 ? error?.data?.detail : `No se pudo ${id ? 'editar' : 'crear'} el ticket de soporte`,
          )
          reject(error)
        })
    })
  }

  return {
    supportTicket,
    setSupportTicket,
    handleChange,
    stepsCompleted,
    markVisualizedStep,
    saveSupportTicket,
    isBusy,
    handleFileInputChange,
    deleteFile,
    handleMessageContentChange,
    messagesToShow,
    lastMessageId,
    isTicketClosed,
    handleDateChange,
    isSuperAdmin,
    isClientAdmin,
    isErrorTicket,
    handleChangeGalleryFiles,
    handleReorderGallery,
    deleteGalleryImage,
    handleGalleryAltChange,
  }
}
