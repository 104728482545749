export const authRoles = {
  SA: ['SA'], // Only Super Admin has access
  ADMIN: ['SA', 'ADMIN'], // Only SA & Admin has access
  CLIENT_ADMIN: ['SA', 'ADMIN', 'CLIENT_ADMIN'], // ...
  CLIENT: ['SA', 'ADMIN', 'CLIENT_ADMIN', 'CLIENT'],
}

export const chartsColors = [
  '#2e3f50', //
  '#bc5c5c',
  '#bc9f5c',
  '#8fbc5c',
  '#5cb6bc',
  '#5c8abc',
  '#5c63bc',
  '#9e5cbc',
  '#bc5c8c',
  '#bc5c5c',
]

export const modules = {
  SITE: 'MediaSite',
  TRACKER: 'MediaTracker',
  BLOG: 'MediaBlog',
  PUSH: 'MediaPush',
  INVESTOR: 'MediaInvestor',
  OWNER: 'MediaOwner',
}

export const routes = {
  core: {
    404: `/404/`,
    dev404: `/dev-404-page/`,
    html404: `/404.html`,
    root: `/`,
    change_password: `/cambiar_contrasena/`,
    my_profile: `/mi_perfil/`,
    login: `/sesion/inicio/`,
    custom_login: (loginPath: string) => `/sesion/inicio/${loginPath}/`,
    users: `/usuarios/`,
    add_user: `/usuarios/nuevo/`,
    edit_user: (id: number) => `/usuarios/editar/${id}/`,
    assign_user_permissions: (id: number) => `/usuarios/asignar_permisos/${id}/`,
    clients: `/clientes/`,
    add_client: `/clientes/nuevo/`,
    edit_client: (id: number) => `/clientes/editar/${id}/`,
    login_templates: `/configuracion/pantallas_inicio/`,
    add_login_template: `/configuracion/pantallas_inicio/nuevo/`,
    edit_login_template: (id: number) => `/configuracion/pantallas_inicio/editar/${id}/`,
    groups_and_permissions: `/configuracion/grupos_y_permisos/`,
    support_tickets: `/tickets_soporte/`,
    add_support_ticket: `/tickets_soporte/nuevo`,
    edit_support_ticket: (id: number) => `/tickets_soporte/${id}`,
  },
  site: {
    my_website: `/mediasite/misitioweb/`,
    my_website_v2: `/mediasite/mi_sitio_web_v2/`,
    site_compiler: `/mediasite/compilador_de_sitios_web/`,
    dns: `/mediasite/dns/`,
    published_sites: `/mediasite/sitiospublicados/`,
    fonts: `/mediasite/fuentes/`,
    add_font: `/mediasite/fuentes/nuevo/`,
    edit_font: (id: number) => `/mediasite/fuentes/editar/${id}/`,
    templates: `/mediasite/templates/`,
    add_template: `/mediasite/templates/nuevo/`,
    edit_template: (id: number) => `/mediasite/templates/editar/${id}/`,
    template_categories: `/mediasite/templates/categorias/`,
    add_template_category: `/mediasite/templates/categorias/nuevo/`,
    edit_template_category: (id: number) => `/mediasite/templates/categorias/editar/${id}/`,
    products: `/mediasite/productos/`,
    add_product: `/mediasite/productos/nuevo/`,
    edit_property: (id: number) => `/mediasite/propiedades/editar/${id}/`,
    edit_development: (id: number) => `/mediasite/emprendimientos/editar/${id}/`,
    contacts: `/mediasite/contactos/`,
    preview_contact: (id: number) => `/mediasite/contactos/contacto/${id}/`,
  },
  blog: {
    news: `/mediablog/novedades/`,
    add_news: `/mediablog/novedades/nuevo/`,
    edit_news: (id: number) => `/mediablog/novedades/editar/${id}/`,
    tags: `/mediablog/tags/`,
    add_tags: `/mediablog/tags/nuevo/`,
    edit_tag: (id: number) => `/mediablog/tags/editar/${id}/`,
    preview_news: (id: number) => `/mediablog/novedades/previsualizar/${id}/`,
  },
  push: {
    actions: `/mediapush/destaques/`, //
    add_action: `/mediapush/destaques/nuevo/`,
    edit_action: (id: number) => `/mediapush/destaques/editar/${id}/`,
  },
  tracker: {
    analytics: `/mediatracker/analiticas/`, //
    realtime_analytics: `/mediatracker/analiticastiemporeal/`,
  },
  investor: {
    config: `/mediainvestor/configuracion/`,
    investors: `/mediainvestor/inversores/`,
    edit_investor: (id: number) => `/mediainvestor/inversores/editar/${id}/`,
    developments: `/mediainvestor/emprendimientos/`,
    add_development: `/mediainvestor/emprendimientos/nuevo/`,
    edit_development: (id: number) => `/mediainvestor/emprendimientos/editar/${id}/`,
    assign_development: (id: number) => `/mediainvestor/inversores/asignar/${id}/`,
    news: `/mediainvestor/novedades/`,
    add_news: `/mediainvestor/novedades/nuevo/`,
    edit_news: (id: number) => `/mediainvestor/novedades/editar/${id}/`,
    preview_news: (id: number) => `/mediainvestor/novedades/previsualizar/${id}/`,
  },
  owner: {
    communications: `/mediaowner/comunicaciones/`,
    add_communication: `/mediaowner/comunicaciones/nuevo/`,
    config: `/mediaowner/configuracion/`,
    owners: `/mediaowner/propietarios/`,
    edit_owner: (id: number) => `/mediaowner/propietarios/editar/${id}/`,
    visits: `/mediaowner/visitas/`,
    add_visit: `/mediaowner/visitas/nuevo/`,
    visit_record: (id: number) => `/mediaowner/visitas/registro_visita/${id}/`,
    edit_visit: (id: number) => `/mediaowner/visitas/editar/${id}/`,
    add_news: `/mediaowner/comunicaciones/novedades/nuevo/`,
    edit_news: (id: number) => `/mediaowner/comunicaciones/novedades/editar/${id}/`,
    preview_news: (id: number) => `/mediaowner/comunicaciones/novedades/previsualizar/${id}/`,
    add_report: `/mediaowner/comunicaciones/reportes/nuevo/`,
    edit_report: (id: number) => `/mediaowner/comunicaciones/reportes/editar/${id}/`,
    preview_report: (id: number) => `/mediaowner/comunicaciones/reportes/previsualizar/${id}/`,
    add_appraisal: `/mediaowner/comunicaciones/tasaciones/nuevo/`,
    edit_appraisal: (id: number) => `/mediaowner/comunicaciones/tasaciones/editar/${id}/`,
    preview_appraisal: (id: number) => `/mediaowner/comunicaciones/tasaciones/previsualizar/${id}/`,
    assign_property: (id: number) => `/mediaowner/propietarios/asignar/${id}/`,
  },
  investor_view: {
    communications: `/inversor/comunicaciones/`,
    investor_news_detail: (id: number) => `/inversor/novedades_inversor/${id}/`,
    owner_news_detail: (id: number) => `/inversor/novedades_propietario/${id}/`,
    report_detail: (id: number) => `/inversor/reportes/${id}/`,
    appraisal_detail: (id: number) => `/inversor/tasaciones/${id}/`,
  },
  owner_view: {
    investor_news_detail: (id: number) => `/propietario/novedades_inversor/${id}/`,
    owner_news_detail: (id: number) => `/propietario/novedades_propietario/${id}/`,
    report_detail: (id: number) => `/propietario/reportes/${id}/`,
    appraisal_detail: (id: number) => `/propietario/tasaciones/${id}/`,
    visit_detail: (id: number) => `/propietario/reportes/tus_visitas/${id}/`,
    communications: `/propietario/comunicaciones/`,
  },
  jobs: {
    positions: `/mediajobs/posiciones`,
    add_position: `/mediajobs/posiciones/nuevo/`,
    edit_position: (id: number) => `/mediajobs/posiciones/editar/${id}`,
    applications: `/mediajobs/candidatos`,
    edit_application: (id: number) => `/mediajobs/candidatos/editar/${id}`,
  },
  agency: {
    roadmaps: `/mediamarketing/planificacion/`,
    resources: `/mediamarketing/entregables/`,
    add_resource: `/mediamarketing/entregables/nuevo/`,
    edit_resource: (id: number) => `/mediamarketing/entregables/editar/${id}`,
    preview_resource: (id: number) => `/mediamarketing/entregables/previsualizar/${id}`,
  },
}

export const currency_options = [
  {
    value: 'USD',
    label: 'USD (Dólares estadounidenses)',
  },
  {
    value: 'ARS', //
    label: 'ARS (pesos Argentinos)',
  },
]

export const operation_types = ['Venta', 'Alquiler', 'Temporario']

export const appraisal_property_types = [
  'Departamento', //
  'Casa',
  'PH',
  'Oficina',
  'Local',
  'Cochera',
  'Terreno',
  'Otro',
]

export const property_types = [
  'Departamento', //
  'Casa',
  'PH',
  'Oficina',
  'Local',
  'Cochera',
  'Terreno',
  'Quinta',
  'Depósito',
  'Campo',
  'Edificio comercial',
  'Otro',
]
export const development_property_types = [
  'Edificio residencial', //
  'Edificio de oficinas',
  'Edificio mixto',
  'Hotel',
  'Club',
  'Centro logístico',
  'Loteo',
  'Barrio privado',
  'Rural',
  'Náutico',
  'Centro comercial',
  'Otro',
]

export const parking_lot_options = [
  'Privada cubierta',
  'Privada descubierta',
  'Privada semicubierta',
  'Sin especificar',
]

export const age_options = [
  'En construcción', //
  'A estrenar',
  '1 a 5 años',
  '6 a 10 años',
  '11 a 20 años',
  '21 a 50 años',
  'Más de 50 años',
  'Sin especificar',
]

export const ageToString = (age: number) => {
  switch (true) {
    case age < 0:
      return age_options[0]
    case age === 0:
      return age_options[1]
    case age <= 5 && age > 0:
      return age_options[2]
    case age <= 10 && age > 5:
      return age_options[3]
    case age <= 20 && age > 10:
      return age_options[4]
    case age <= 50 && age > 20:
      return age_options[5]
    case age > 50:
      return age_options[6]
    default:
      return undefined
  }
}

export const orientation_options = [
  'Sur', //
  'Norte',
  'Este',
  'Oeste',
  'Noreste',
  'Sudeste',
  'Noroeste',
  'Suroeste',
  'Sin especificar',
]
export const disposition_options = [
  'Frente', //
  'Contrafrente',
  'Lateral',
  'Interno',
  'Sin especificar',
]
export const luminosity_options = ['Poco luminoso', 'Luminoso', 'Muy luminoso', 'Sin especificar']
export const preservation_state_options = [
  'Mal estado',
  'Estado regular',
  'Buen estado',
  'Excelente estado',
  'Sin especificar',
]
export const building_quality_options = [
  'Baja calidad',
  'Calidad promedio',
  'Buena calidad',
  'Excelente calidad',
  'Sin especificar',
]

export const zonification_options = [
  'APH',
  'ARE',
  'C1',
  'C2',
  'C3 I',
  'C3 II',
  'E1',
  'E2',
  'E3',
  'E4',
  'I1',
  'I2',
  'NE',
  'P',
  'R1a',
  'R1bI',
  'R1bII',
  'R2aI',
  'R2aII',
  'R2bI',
  'R2bII',
  'R2bIII',
  'RU',
  'RUA',
  'U',
  'UF',
  'UP',
  'UP/APH 2',
]
export const region_options = ['Norte', 'Sur', 'Este', 'Oeste']

export const construction_types_options = ['Residencial', 'Comercial', 'Industrial', 'Mixta', 'Otro']

export const growth_rate_options = ['Sin especificar', 'Decreciente', 'Estable', 'Creciente', 'Acelerado']

export const location_quality_options = ['Sin especificar', 'Mala', 'Regular', 'Buena', 'Excelente']

export const security_options = ['Sin especificar', 'Baja', 'Regular', 'Alta', 'Máxima']

export const supply_options = ['Sin especificar', 'Escasa', 'Moderada', 'Abundante', 'Sobre oferta']

export const demand_options = ['Sin especificar', 'Inexistente', 'Escasa', 'Moderada', 'Alta']

export const values_options = ['Sin especificar', 'Decreciente', 'Estable', 'Creciente', 'En alza']

export const surface_measurement_options = ['M2', 'HA']

export const computableTotalSurface = ({
  roofed_surface,
  semiroofed_surface,
  unroofed_surface,
}: {
  roofed_surface?: number
  semiroofed_surface?: number
  unroofed_surface?: number
}) => (roofed_surface ?? 0) + (semiroofed_surface ?? 0) * 0.5 + (unroofed_surface ?? 0) * 0.3

export const measurement_options = ['M', 'KM']

export const extra_services_options = [
  'Agua Corriente',
  'Electricidad',
  'Gas Natural',
  'Cable',
  'Internet',
  'Teléfono',
  'Pavimento',
  'Cloaca',
]
export const extra_general_options = [
  'Balcón',
  'Baulera',
  'Aire acond.',
  'Alarma',
  'Terraza',
  'Sótano',
  'Jardín',
  'Patio',
  'Vestidor',
  'Apto crédito',
  'Apto prof.',
  'Seguridad',
]
export const extra_amenities_options = [
  'Piscina',
  'Piscina climatizada',
  'Calefacción',
  'Gimnasio',
  'Parrilla',
  'Quincho',
  'SUM',
  'Área deportiva',
  'Área de juegos',
  'Sauna',
  'Solarium',
  'Spa',
  'Laundry',
  'Espacios verdes',
  'Área coworking',
  'Hidromasaje',
  'Bicicletero',
  'Rooftop',
]

export const subdivision_options = ['Piso', 'Subsuelo', 'Nivel', 'Lote', 'Fracción', 'Área']

export const valuation_period_rent_options = ['Mensual', 'Semestral', 'Anual']

export const valuation_period_temporary_rent_options = [
  'Diario',
  'Fin de semana',
  'Semanal',
  'Quincenal',
  'Mensual',
  'Anual',
]
export const construction_status_options = ['Pre-venta', 'En pozo', 'En construcción', 'Terminado']

export const payment_method_options = ['Contado', 'Financiado', 'Flexible']

export const elevators_brand_options = ['Schindler', 'Otis', 'Hyundai', 'Thyssenkrupp', 'Otro']

export const climate_control_options = [
  'Aire acondicionado central',
  'Calefacccion central',
  'Mediante radiadores',
  'Mediante equipos Split frío-calor',
  'Suelo radiante',
  'Otro',
  'Sin especificar',
]

export const fire_detection_system_options = [
  'No posee',
  'Detectores de humo',
  'Detectores de calor',
  'Detectores de llama',
  'Sin especificar',
]

export const fire_extinguishing_system_options = ['Rociadores automáticos', 'Extintores y mangeras', 'Sin especificar']

export const development_extra_services_options = [
  'Agua Corriente',
  'Electricidad',
  'Gas Natural',
  'Cable',
  'Internet',
  'Teléfono',
  'Pavimento',
  'Cloaca',
  'Limpieza',
  'Recolección de residuos',
]
export const development_extra_general_options = [
  'Hall de acceso',
  'Bauleras',
  'Cocheras de cortesía',
  'Accesibilidad',
  'CCTV',
  'Garita de seguridad',
  'Cerco perimetral',
  'Grupo electrógeno',
  'Apto blanqueo',
  'Apto crédito',
  'Apto prof.',
  'Seguridad 24hs',
]
export const development_extra_amenities_options = [
  'Piscina',
  'Piscina climatizada',
  'Bar/Restaurante',
  'Gimnasio',
  'Parrilla',
  'Quincho',
  'SUM',
  'Área deportiva',
  'Área de juegos',
  'Sauna',
  'Solarium',
  'Spa',
  'Laundry',
  'Espacios verdes',
  'Área coworking',
  'Hidromasaje',
  'Bicicletero',
  'Rooftop',
]

export const experience_level_options = ['Sin especificar', 'Trainee', 'Junior', 'Semi-senior', 'Senior']

export const mode_options = ['Sin especificar', 'Presencial', 'Remota', 'Híbrida']

export const application_status_options = [
  'En revisión',
  'Descartado',
  'Entrevistas',
  'Evaluación',
  'Pre-seleccionado',
  'Negociación',
  'Contratado',
]

export const languages_options = [
  'Inglés',
  'Español',
  'Chino (Mandarín)',
  'Francés',
  'Alemán',
  'Italiano',
  'Japonés',
  'Portugués',
  'Ruso',
  'Árabe',
]

export const language_level_options = [
  'No conoce el idioma',
  'Básico',
  'Conversación',
  'Profesional',
  'Nativo o lengua materna',
]

export const job_interviews_options = ['Presencial', 'Teléfónica', 'Video Llamada', 'Chat']

export const application_status_colors: { [key: string]: string } = {
  'En revisión': '#f89a0f',
  Descartado: '#d83d1d',
  Entrevistas: '#6a2365',
  Evaluación: '#e14a77',
  'Pre-seleccionado': '#1dafe6',
  Negociación: '#236a61',
  Contratado: '#3ba84e',
}

export const contactQueryType = {
  development_query: 'Emprendimiento',
  subscription: 'Newsletter',
  property_query: 'Propiedad',
  appraisal: 'Tasación',
  contact: 'Contacto',
}

export const contactTypeBreadcrumb = {
  development_query: 'Consulta de un emprendimiento',
  subscription: 'Nuevo suscriptor newsletter',
  property_query: 'Consulta de una propiedad',
  appraisal: 'Solicitud de tasación',
  contact: 'Contacto web',
}
export const support_ticket_priority_colors = {
  Urgente: '#f24726',
  Alta: '#fac710',
  Normal: '#a7d5f9',
  Baja: '#bdbbbb',
}

export const support_ticket_status_colors = {
  'No iniciado': '#ADADAD',
  'En curso': '#da0063',
  'En espera': '#F89A0F',
  Cerrado: '#8fd14f',
}

export const support_ticket_areas = ['Técnico', 'Comercial', 'Facturación']

export const uploadButtonLabel = 'buscar archivos'

export const inquiries_options = ['Referido', 'Teléfono', 'Email', 'Email Marketing', 'Otro']

export const external_websites_inquiries_options = ['ArgenProp', 'ZonaProp', 'MercadoLibre', 'Otro']

export const multimedia_video_options = ['YouTube', 'Vimeo'] as const

export const multimedia_360_tours_options = ['Matterport', 'Hauzd', 'Kuula', 'RoundMe', '360 MagicTour'] as const

export const videoPrefixes = {
  YouTube: 'https://www.youtube.com/watch?v=',
  Vimeo: 'https://vimeo.com/',
  Matterport: 'https://my.matterport.com/show/?m=',
  Hauzd: 'https://hauzd.com/',
  Kuula: 'https://kuula.co/share/collection/',
  RoundMe: 'https://roundme.com/embed/',
  '360 MagicTour': 'https://america.360magictour.com/projects/?project=',
}

export const embedPrefixes = {
  YouTube: 'https://www.youtube.com/embed/',
  Vimeo: 'https://player.vimeo.com/video/',
}

export const mapStyles = [
  {
    stylers: [
      {
        saturation: -0,
      },
    ],
  },
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: 0,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'true',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c5d3ca',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: -25,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: -35,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: -25,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c5ced3',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

export const contacts_status_colors = {
  'Nueva oportunidad': '#676767',
  'Oportunidad asignada': '#F42EC9',
  Contactado: '#FF7A00',
  Visitó: '#3C58D3',
  'Oportunidad ganada': '#30DF57',
  'Oportunidad perdida': '#FF0000',
  'Oportunidad descartada': '#212121',
}

export const contactTypeTranslations = {
  appraisal: 'tasación',
  development_query: 'emprendimiento',
  subscription: 'newsletter',
  property_query: 'propiedad',
  contact: 'contacto',
  Emprendimiento: 'emprendimiento',
}

export const globalContainerProps = {
  container: true,
  spacing: 2,
}

export const defaultButtonLabels = {
  save: 'Guardar desactivada',
  publish: 'Guardar activa',
}

export const resources_status_colors = {
  Pendiente: '#676767',
  'En curso': '#F42EC9',
  Finalizado: '#30DF57',
}

export const agency_areas_categories = {
  Comunicación: [
    'Desarrollo contenidos PPC',
    'Desarrollo contenidos ORG',
    'Desarrollo contenidos Email MKTG',
    'Key visual',
    'Nota blog',
    'Branding',
    'Estrategias de Contenidos',
    'Estrategia de Marca',
    'Lineamientos Generales',
    'Reportes / Informes',
    'Minuta',
    'Varios',
  ],
  Desarrollo: ['Sitio Web', 'Landing Page', 'Pre Landing', 'Prototipo', 'Instructivos', 'Varios'],
  Audiovisual: ['Branding', 'Equipo', 'Producto', 'Testimoniales', 'RRSS', 'Varios'],
  Prensa: [
    'Notas de Prensa',
    'Artículos en Medios',
    'Organización de Eventos',
    'Relaciones con Medios',
    'Conferencias de Prensa',
    'Menciones y Publicaciones',
    'Varios',
  ],
  'Recursos Generales': [
    'Logos',
    'Tipografía',
    'Imágenes',
    'Iconografía',
    'Paleta de Colores',
    'Patrones Gráficos',
    'Manual de Marca',
    'Varios',
  ],
}
