import { useAppSelector } from '@redux/reduxHooks'

interface UseTableConfigProps {
  uniqueId: string
}

export const useTableConfig = ({ uniqueId }: UseTableConfigProps) => {
  const VALID_SEARCH_LENGTH = 3

  const {
    search = '',
    sorted = {},
    filter = {},
    activeTab = 'all',
  } = useAppSelector((state) => state.tableConfigs[uniqueId as keyof typeof state.tableConfigs]) ?? {}

  const validSearch = search.length >= VALID_SEARCH_LENGTH ? search : ''

  return {
    search: validSearch,
    sorted,
    filter,
    activeTab,
  }
}
