import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { Appraisal, GalleryPayload } from '@types'

const appraisalsApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppraisals: builder.query<Appraisal[], { clientId: number }>({
      query: getAllByClientIdQuery('owner/tasaciones'),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Appraisal' as const, id })), 'Appraisal'] : ['Appraisal'],
    }),
    getAppraisalById: builder.query<Appraisal, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('owner/tasaciones'),
      providesTags: (_result, _error, { id }) => [{ type: 'Appraisal', id }],
    }),
    deleteAppraisal: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation('owner/tasaciones'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted({ id, clientId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            appraisalsApi.util.updateQueryData('getAppraisals', { clientId }, (draftAppraisals) => {
              return draftAppraisals.filter((draftAppraisal) => draftAppraisal.id !== id)
            }),
          )
        } catch {}
      },
    }),
    createAppraisal: builder.mutation<Appraisal, { data: Partial<Appraisal> }>({
      query: createOneMutation('owner/tasaciones'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdAppraisal } = await queryFulfilled
          dispatch(
            appraisalsApi.util.updateQueryData(
              'getAppraisals',
              { clientId: createdAppraisal.client },
              (draftAppraisals) => {
                draftAppraisals.unshift(createdAppraisal)
                return draftAppraisals
              },
            ),
          )
        } catch {}
      },
    }),
    updateAppraisal: builder.mutation<Appraisal, { id: number; data: Partial<Appraisal> }>({
      query: updateByIdMutation('owner/tasaciones'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedAppraisal } = await queryFulfilled
          dispatch(
            appraisalsApi.util.updateQueryData(
              'getAppraisalById',
              { id, clientId: updatedAppraisal.client },
              (draft) => {
                Object.assign(draft, updatedAppraisal)
              },
            ),
          )
          dispatch(
            appraisalsApi.util.updateQueryData(
              'getAppraisals',
              { clientId: updatedAppraisal.client },
              (draftAppraisals) => {
                return draftAppraisals.map((draftAppraisal) =>
                  draftAppraisal.id !== id ? draftAppraisal : updatedAppraisal,
                )
              },
            ),
          )
        } catch {}
      },
    }),
    uploadAppraisalImages: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('owner/tasaciones', 'galeria'),
      invalidatesTags: ['Appraisal', 'CommunicationTable'],
    }),
    uploadAppraisalBlueprints: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('owner/tasaciones', 'galeria_planos'),
      invalidatesTags: ['Appraisal', 'CommunicationTable'],
    }),
    uploadAppraisalFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('owner/tasaciones', 'galeria_archivos'),
      invalidatesTags: ['Appraisal', 'CommunicationTable'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAppraisalsQuery,
  useGetAppraisalByIdQuery,
  useDeleteAppraisalMutation,
  useCreateAppraisalMutation,
  useUpdateAppraisalMutation,
  usePrefetch: usePrefetchAppraisals,
  useUploadAppraisalImagesMutation,
  useUploadAppraisalBlueprintsMutation,
  useUploadAppraisalFilesMutation,
} = appraisalsApi
