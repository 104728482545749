import {
  Appraisal,
  AppraisalImagesData,
  GalleryImage,
  GenericProperty,
  PickByType,
  PropertiesCompareContent,
  TokkoProperties,
  TokkoPropertyPhoto,
} from '@types'
import { ChangeEvent, useEffect, useState } from 'react'
import moment from 'moment'
import {
  ageToString,
  extra_amenities_options,
  extra_general_options,
  extra_services_options,
  measurement_options,
  surface_measurement_options,
} from '@constants'
import {
  onlySavedFilesData,
  onlyUnique,
  pendingToast,
  pick,
  realAddressComponents,
  reorder,
  updateErrorToast,
  updateSuccessToast,
} from '@utils'
import { toast } from 'react-toastify'
import {
  useCreateAppraisalMutation,
  useGetAppraisalByIdQuery,
  useUpdateAppraisalMutation,
} from '@endpoints/appraisalsEndpoint'
import { useSession } from './useSession'
import { array, number, string } from 'yup'
import { useValidation } from './useValidation'
import { useGallery } from './useGallery'

export interface Props {
  id?: number
}

export const useAppraisal = ({ id }: Props) => {
  const { client } = useSession()

  const {
    data: queriedAppraisal,
    isLoading: isLoadingAppraisal,
    isError: isErrorAppraisal,
    error: errorAppraisal,
    refetch: refetchAppraisal,
    isFetching: isFetchingAppraisal,
  } = useGetAppraisalByIdQuery({ id: id!, clientId: client?.id! }, { skip: !client || !id })

  useEffect(() => {
    setAppraisal(queriedAppraisal ?? blankAppraisal)
  }, [queriedAppraisal, client?.id])

  const [updateAppraisal, { isLoading: isUpdating }] = useUpdateAppraisalMutation()
  const [createAppraisal, { isLoading: isCreating }] = useCreateAppraisalMutation()

  const isSaving = isUpdating || isCreating

  const [isLoadingImagesData, setIsLoadingImagesData] = useState(false)
  const [isLoadingBlueprintsData, setIsLoadingBlueprintsData] = useState(false)

  const blankAppraisal: Partial<Appraisal> = {
    property: undefined,
    title: 'Informe de tasación',
    content: {
      visualizedSteps: [0],
      operations: [],
      valuation_price_percentage: 5,
      valuation_currency: 'USD',
      valuation_price_rent_percentage: 5,
      valuation_currency_rent: 'USD',
      valuation_price_temporary_rent_percentage: 5,
      valuation_currency_temporary_rent: 'USD',
      footnotes_title: 'Notas al pie',
      demand: 'Sin especificar',
      supply: 'Sin especificar',
      values: 'Sin especificar',
      security: 'Sin especificar',
      growth_rate: 'Sin especificar',
      location_quality: 'Sin especificar',
      expenses_currency: 'ARS',
      saleComparisonProperties: [],
      parking_lot_type: 'Privada cubierta',
      age: 'Sin especificar',
      orientation: 'Sin especificar',
      disposition: 'Sin especificar',
      luminosity: 'Sin especificar',
      preservation_state: 'Sin especificar',
      building_quality: 'Sin especificar',
      total_surface_measurement: surface_measurement_options[0],
      roofed_surface_measurement: surface_measurement_options[0],
      semiroofed_surface_measurement: surface_measurement_options[0],
      unroofed_surface_measurement: surface_measurement_options[0],
      surface_measurement: surface_measurement_options[0],
      front_measurement: measurement_options[0],
      depth_measurement: measurement_options[0],
      extra_general: [],
      extra_services: [],
      extra_amenities: [],
      valuation_period_rent: 'Mensual',
      valuation_period_temporary_rent: 'Diario',
      property_type: [],
      description: '',
      country: '',
      city: '',
      neighborhood: '',
      state: '',
      valuation_price_has_percentage: false,
      valuation_price_rent_has_percentage: false,
      valuation_price_temporary_rent_has_percentage: false,
      footnotes: '',
      expenses: 0,
      room_amount: 0,
      bathroom_amount: 0,
      toilet_amount: 0,
      suite_amount: 0,
      parking_lot_amount: 0,
      real_address: '',
      floor: '',
      apartment: '',
      region: '',
      zonification: '',
      geo_lat: -34.60373472685532,
      geo_long: -58.3815675939131,
      construction_types: '',
      sale_comparison_reference_points: [],
      roofed_surface: 0,
      semiroofed_surface: 0,
      unroofed_surface: 0,
      total_surface: 0,
      front_measure: 0,
      depth_measure: 0,
      surface: 0,
      created_at: moment().format('YYYY-MM-DDThh:mm'),
    },
    is_active: false,
    owners: [],
    date_published: moment().format('YYYY-MM-DDThh:mm'),
  }
  const [appraisal, setAppraisal] = useState<Partial<Appraisal>>(blankAppraisal)

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.persist) e.persist()
    setAppraisal((prevData) => ({
      ...prevData,
      content: { ...prevData.content, [e.target.name]: e.target.value },
    }))
  }

  const handleReferencePointNameChange = (value: string, index: number) => {
    setAppraisal((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.reference_points![index].name = value
      return newData
    })
  }

  const fetchPhoto = (photo: TokkoPropertyPhoto): Promise<AppraisalImagesData> => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.GATSBY_API_BASE_URL}owner/externo/tokko-propiedades/imagenes/?url=${photo.image}`)
        .then((response) => {
          if (response.ok) return response.blob()
          throw new Error('Fetch error')
        })
        .then((blob) => {
          resolve({
            file: new File([blob], 'photo.jpg', {
              type: blob.type,
            }),
            text_alt: photo.description ?? '',
            order: photo.order,
          })
        })
        .catch((error) => reject(error))
    })
  }

  const fetchPhotos = async (photos: TokkoProperties['photos']) => {
    const result = await Promise.allSettled(photos.map((photo) => fetchPhoto(photo)))
    return (result.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<AppraisalImagesData>[]).map(
      (res) => res.value,
    )
  }

  const handleChangeProperty = async (selectedProperty: GenericProperty) => {
    let images_data: AppraisalImagesData[] = []
    let blueprints_data: AppraisalImagesData[] = []
    setIsLoadingImagesData(true)
    fetchPhotos(selectedProperty?.photos?.filter((photo: any) => !photo.is_blueprint) ?? [])
      .then((images_data) => {
        setAppraisal((prevData) => ({
          ...prevData,
          images_data,
        }))
      })
      .catch((error: any) => console.log(error))
      .finally(() => setIsLoadingImagesData(false))
    setIsLoadingBlueprintsData(true)
    fetchPhotos(selectedProperty?.photos?.filter((photo: any) => photo.is_blueprint) ?? [])
      .then((blueprints_data) => {
        setAppraisal((prevData) => ({
          ...prevData,
          blueprints_data,
        }))
      })
      .catch((error: any) => console.log(error))
      .finally(() => setIsLoadingBlueprintsData(false))
    setAppraisal((prevData) => ({
      ...prevData,
      property: selectedProperty.id,
      owners: selectedProperty.owners ?? [],
      images_data,
      blueprints_data,
      content: {
        expenses_currency: 'ARS',
        visualizedSteps: prevData.content?.visualizedSteps ?? [0],
        operations: selectedProperty?.operations ?? [],
        property_type: selectedProperty?.type?.name ? [selectedProperty?.type?.name] : selectedProperty?.property_type,
        expenses: Number(selectedProperty?.expenses ?? 0),
        room_amount: Number(selectedProperty?.room_amount ?? 0),
        suite_amount: Number(selectedProperty?.suite_amount ?? 0),
        bathroom_amount: Number(selectedProperty?.bathroom_amount ?? 0),
        toilet_amount: Number(selectedProperty?.toilet_amount ?? 0),
        parking_lot_amount: Number(selectedProperty?.parking_lot_amount ?? 0),
        disposition: selectedProperty?.disposition,
        orientation: selectedProperty?.orientation,
        description: selectedProperty?.rich_description ?? selectedProperty?.description ?? '',
        age: ageToString(Number(selectedProperty?.age)),
        real_address: realAddressComponents(selectedProperty?.real_address)?.address ?? selectedProperty?.address ?? '',
        fake_address: selectedProperty?.fake_address ?? '',
        full_address: selectedProperty?.real_address ?? '',
        geo_lat: selectedProperty?.geo_lat ?? 0,
        geo_long: selectedProperty?.geo_long ?? 0,
        total_surface: Number(selectedProperty?.total_surface?.split(' ')[0] ?? 0),
        roofed_surface: Number(selectedProperty?.roofed_surface ?? 0),
        semiroofed_surface: Number(selectedProperty?.semiroofed_surface ?? 0),
        unroofed_surface: Number(selectedProperty?.unroofed_surface ?? 0),
        surface: Number(selectedProperty?.surface) ?? 0,
        front_measure: Number(selectedProperty?.front_measure) ?? 0,
        depth_measure: Number(selectedProperty?.depth_measure) ?? 0,
        roofed_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        unroofed_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        semiroofed_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        total_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        computable_total_surface: Math.round(
          Number(selectedProperty?.roofed_surface ?? 0) +
            Number(selectedProperty?.semiroofed_surface ?? 0) * 0.5 +
            Number(selectedProperty?.unroofed_surface ?? 0) * 0.3,
        ),
        tokko_valuation_price: Number(selectedProperty?.valuation_price?.split(' ')[0] ?? 0),
        square_meter_value: Math.round(
          Number(selectedProperty?.valuation_price?.split(' ')[0] ?? 0) /
            (Number(selectedProperty?.roofed_surface ?? 0) +
              Number(selectedProperty?.semiroofed_surface ?? 0) * 0.5 +
              Number(selectedProperty?.unroofed_surface ?? 0) * 0.3),
        ),
        front_measurement: measurement_options[0],
        depth_measurement: measurement_options[0],
        extra_services:
          selectedProperty?.tags
            ?.filter((tag: any) => tag.type === 1 && extra_services_options.includes(tag.name))
            .map((tag: any) => tag.name) ?? [],
        extra_general:
          selectedProperty?.tags
            ?.filter((tag: any) => tag.type === 2 && extra_general_options.includes(tag.name))
            .map((tag: any) => tag.name) ?? [],
        extra_amenities:
          selectedProperty?.tags
            ?.filter((tag: any) => tag.type === 3 && extra_amenities_options.includes(tag.name))
            .map((tag: any) => tag.name) ?? [],
        valuation_price_percentage: 5,
        valuation_currency: 'USD',
        valuation_price_rent_percentage: 5,
        valuation_currency_rent: 'USD',
        valuation_price_temporary_rent_percentage: 5,
        valuation_currency_temporary_rent: 'USD',
        footnotes_title: 'Notas al pie',
        demand: 'Sin especificar',
        supply: 'Sin especificar',
        values: 'Sin especificar',
        security: 'Sin especificar',
        growth_rate: 'Sin especificar',
        location_quality: 'Sin especificar',
        floor: realAddressComponents(selectedProperty?.real_address)?.floor ?? '',
        apartment: realAddressComponents(selectedProperty?.real_address)?.apartment ?? '',
      },
    }))
  }

  const removeProperty = () => {
    setAppraisal((prevData) => {
      return {
        ...prevData,
        property: undefined,
        content: { ...blankAppraisal.content, visualizedSteps: prevData.content?.visualizedSteps ?? [0] },
      }
    })
  }

  // Order is mandatory because of the parallel upload
  const ordered_images_data = appraisal?.images_data?.map((image, index) => ({ ...image, order: index }))
  const ordered_blueprints_data = appraisal?.blueprints_data?.map((image, index) => ({ ...image, order: index }))

  const postOrPatch = ({ is_active }: Partial<Appraisal>) => {
    const data = {
      ...appraisal,
      images_data: onlySavedFilesData(ordered_images_data),
      blueprints_data: onlySavedFilesData(ordered_blueprints_data),
      content: {
        ...appraisal?.content,
        reference_points: appraisal?.content?.reference_points?.filter(
          (refPoint: { name: string; lat: number; lng: number }) => !!refPoint.name,
        ),
      },
      date_published: appraisal.date_published ?? new Date().toISOString(),
      is_active,
      client: id ? appraisal?.client : client?.id,
    }

    return id ? updateAppraisal({ id, data }) : createAppraisal({ data })
  }

  const { uploadAppraisalImages, uploadAppraisalBlueprints } = useGallery()

  const saveAppraisal = async (is_active?: boolean) => {
    const toastId = pendingToast(toast, `${id ? 'Guardando' : 'Creando'} tasación ...`)
    return postOrPatch({ is_active: is_active ?? false })
      .unwrap()
      .then((result) => {
        const imagesToUpload = ordered_images_data?.filter((id) => id.file instanceof File) as GalleryImage[]
        const blueprintsToUpload = ordered_blueprints_data?.filter((id) => id.file instanceof File) as GalleryImage[]
        if (imagesToUpload?.length) uploadAppraisalImages({ id: result.id, gallery: imagesToUpload })
        if (blueprintsToUpload?.length) uploadAppraisalBlueprints({ id: result.id, gallery: blueprintsToUpload })

        updateSuccessToast(toast, toastId, `Tasación ${id ? 'editada' : 'creada'} con éxito`)
      })
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403 ? error?.data?.detail : `No se pudo ${id ? 'editar' : 'crear'} la tasación`,
        )
        throw error
      })
  }

  const markVisualizedStep = (step: number) => {
    if (!appraisal?.content?.visualizedSteps?.includes(step))
      setAppraisal((prevData) => ({
        ...prevData,
        content: {
          ...prevData.content,
          visualizedSteps: [...(prevData?.content?.visualizedSteps ?? []), step],
        },
      }))
  }

  const addOwner = (userId: number) => {
    if (appraisal?.owners?.includes(userId)) return
    setAppraisal((prevData) => ({
      ...prevData,
      owners: [...(prevData?.owners ?? []), userId],
    }))
  }

  const toggleOwners = (users: number[]) => {
    setAppraisal((prevData) => ({
      ...prevData,
      owners: users.every((user) => prevData?.owners?.includes(user))
        ? prevData?.owners?.filter((id) => !users.includes(id))
        : [...(prevData?.owners ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeOwner = (userId: number) => {
    setAppraisal((prevData) => ({
      ...prevData,
      owners: prevData?.owners?.filter((id) => id !== userId),
    }))
  }

  const { validationErrors } = useValidation({
    shape: {
      title: string().required('El título es requerido'),
      property: number().required('Debe seleccionar una propiedad'),
      operations: array().min(1, 'Debe seleccionar al menos un tipo de operación'),
      property_type: array().min(1, 'Debe seleccionar al menos un tipo de propiedad'),
      description: string().required('La descripción es requerida'),
      country: string().required('El país es requerido'),
      city: string().required('La ciudad es requerida'),
      state: string().required('La provincia es requerida'),
      valuation_review: string().required('La opinión de valor es requerida'),
      valuation_price: number().when('operations', (values, schema) =>
        values[0]?.includes('Venta') ? schema.positive('Debe ingresar un precio de venta') : schema,
      ),
      valuation_price_rent: number().when('operations', (values, schema) =>
        values[0]?.includes('Alquiler') ? schema.positive('Debe ingresar un precio de alquiler') : schema,
      ),
      valuation_price_temporary_rent: number().when('operations', (values, schema) =>
        values[0]?.includes('Temporario') ? schema.positive('Debe ingresar un precio de alquiler temporario') : schema,
      ),
      images_data: array().min(1, 'Debe subir al menos una imagen'),
    },
    data: {
      ...appraisal,
      ...pick(
        appraisal?.content!,
        'operations',
        'property_type',
        'description',
        'country',
        'city',
        'state',
        'valuation_review',
        'valuation_price',
        'valuation_price_rent',
        'valuation_price_temporary_rent',
      ),
    },
  })

  const setValue = <T>(name: keyof PickByType<Appraisal, T>, value: T) => {
    setAppraisal((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const toggleContentValue = (name: keyof Appraisal['content']) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        [name]: !prevData.content![name],
      },
    }))
  }

  const setContentValue = <T>(name: keyof Appraisal['content'], value: T) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: { ...prevData.content, [name]: value },
    }))
  }

  const setFullAdress = ({
    real_address,
    country,
    city,
    neighborhood,
    state,
    geo_lat,
    geo_long,
  }: {
    real_address: string
    country: string
    city: string
    neighborhood: string
    state: string
    geo_lat?: number
    geo_long?: number
  }) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content, //
        real_address,
        country,
        city,
        neighborhood,
        state,
        geo_lat,
        geo_long,
      },
    }))
  }

  const addReferencePoint = (lat: number, lng: number) => {
    setAppraisal((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        reference_points: [...(prev.content?.reference_points ?? []), { lat, lng, name: '' }],
      },
    }))
  }

  const removeReferencePoint = (index: number) => {
    setAppraisal((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        reference_points: prev.content?.reference_points?.filter((_: any, i: number) => i !== index),
      },
    }))
  }

  const setLatLng = (lat: number, lng: number) => {
    setAppraisal((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        geo_lat: lat,
        geo_long: lng,
      },
    }))
  }

  const addImages = (images: File[] | null) => {
    if (!images) return
    setAppraisal((prevData) => ({
      ...prevData,
      images_data: [
        ...(prevData?.images_data ?? []),
        ...images.map((file) => ({
          file,
          text_alt: '',
        })),
      ],
    }))
  }

  const addBlueprints = (blueprints: File[] | null) => {
    if (!blueprints) return
    setAppraisal((prevData) => ({
      ...prevData,
      blueprints_data: [
        ...(prevData?.blueprints_data ?? []),
        ...blueprints.map((file) => ({
          file,
          text_alt: '',
        })),
      ],
    }))
  }

  const reorderImages = (prevIndex: number, newIndex: number) =>
    setAppraisal((prevData) => {
      if (!prevData?.images_data) return prevData
      return {
        ...prevData,
        images_data: reorder(prevData?.images_data, prevIndex, newIndex),
      }
    })

  const reorderBlueprints = (prevIndex: number, newIndex: number) =>
    setAppraisal((prevData) => {
      if (!prevData?.blueprints_data) return prevData
      return {
        ...prevData,
        blueprints_data: reorder(prevData?.blueprints_data, prevIndex, newIndex),
      }
    })

  const deleteImage = (index: number) => {
    setAppraisal((prevData) => {
      return {
        ...prevData,
        images_data: prevData.images_data?.filter((_, i) => i !== index),
      }
    })
  }

  const deleteBlueprint = (index: number) => {
    setAppraisal((prevData) => {
      return {
        ...prevData,
        blueprints_data: prevData.blueprints_data?.filter((_, i) => i !== index),
      }
    })
  }

  const setImageText = (index: number, text: string) => {
    setAppraisal((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      newFiles[index] = { ...newFiles[index], text_alt: text }
      return {
        ...prevData,
        images_data: newFiles,
      }
    })
  }

  const setBlueprintText = (index: number, text: string) => {
    setAppraisal((prevData) => {
      const newFiles = [...(prevData?.blueprints_data ?? [])]
      newFiles[index] = { ...newFiles[index], text_alt: text }
      return {
        ...prevData,
        blueprints_data: newFiles,
      }
    })
  }

  const setSaleCompareValue = <T>(index: number, name: keyof PropertiesCompareContent, value: T) => {
    setAppraisal((prevData) => {
      const newSaleComparisonProperties = [...(prevData.content?.saleComparisonProperties ?? [])]
      newSaleComparisonProperties[index] = { ...newSaleComparisonProperties[index], [name]: value }
      return {
        ...prevData,
        content: {
          ...prevData.content,
          saleComparisonProperties: newSaleComparisonProperties,
        },
      }
    })
  }

  const addSaleCompareProperty = (property: Partial<PropertiesCompareContent>) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        saleComparisonProperties: [...(prevData.content?.saleComparisonProperties ?? []), property],
      },
    }))
  }

  const removeCompareProperty = (index: number) => {
    setAppraisal((prevData) => {
      return {
        ...prevData,
        content: {
          ...prevData.content,
          saleComparisonProperties: prevData.content?.saleComparisonProperties?.filter((_, i) => i !== index),
        },
      }
    })
  }

  return {
    validationErrors,
    appraisal,
    handleContentChange,
    isLoadingAppraisal,
    handleChangeProperty,
    isLoadingImagesData,
    isLoadingBlueprintsData,
    removeProperty,
    saveAppraisal,
    isFetchingAppraisal,
    markVisualizedStep,
    addOwner,
    removeOwner,
    toggleOwners,
    handleReferencePointNameChange,
    setValue,
    setContentValue,
    setFullAdress,
    addReferencePoint,
    removeReferencePoint,
    setLatLng,
    toggleContentValue,
    addImages,
    addBlueprints,
    reorderImages,
    reorderBlueprints,
    deleteImage,
    deleteBlueprint,
    setImageText,
    setBlueprintText,
    setSaleCompareValue,
    addSaleCompareProperty,
    removeCompareProperty,
    isErrorAppraisal,
    errorAppraisal,
    refetchAppraisal,
    isSaving,
  }
}
