import { Box, Typography } from '@mui/joy'
import { globalStyles } from '@styles/styles'
import React from 'react'
import { InfoTooltip } from './InfoTooltip'

interface FloatingLabelProps {
  label: React.ReactNode
  required?: boolean
  children: React.ReactNode
  tooltip?: string
}

export const FloatingLabel = ({
  label,
  required,
  children,
  tooltip,
  ...props
}: FloatingLabelProps & React.ComponentProps<typeof Typography>) => {
  return (
    <Box position="relative">
      <Typography
        endDecorator={tooltip && <InfoTooltip text={tooltip} />}
        color="neutral"
        {...props}
        sx={{
          ...(required ? globalStyles.requiredFieldLabel : {}),
          position: 'absolute',
          top: '-0.5rem',
          fontSize: '0.75rem',
          backgroundColor: 'var(--joy-palette-background-surface)',
          zIndex: 2,
          padding: '0 0.25rem',
          marginLeft: '0.75rem',
          ...props.sx,
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  )
}
