import React, { CSSProperties, Fragment, ReactNode, memo, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, ProgressBar, Row, Spinner, Stack } from 'react-bootstrap'
import Title from '@components/Title'
import { navigate } from 'gatsby'
import {
  AccordionGroup,
  AspectRatio,
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Button as JoyButton,
  Stack as JoyStack,
  Stepper as JoyStepper,
  Menu,
  MenuButton,
  MenuItem,
  ModalClose,
  Skeleton,
  Step,
  StepIndicator,
  Tooltip,
  Typography,
  accordionClasses,
  // accordionDetailsClasses,
  stepClasses,
  stepIndicatorClasses,
} from '@mui/joy'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { globalStyles } from '@styles/styles'
import { Accordion, AccordionDetails, AccordionSummary, Dropdown } from '@mui/joy'
import { JoyStyles } from '@types'
import {
  ArrowsPointingOutIcon,
  BuildingOffice2Icon,
  CheckIcon,
  CloudArrowUpIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  HomeIcon,
  InformationCircleIcon,
  KeyIcon,
  PaperAirplaneIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { BaseModal } from './JoyConfirmationModal'
import { InfoTooltip } from './InfoTooltip'

export interface Step {
  completed: boolean
  content: JSX.Element
  title: string
  subtitle?: string
  isLoading?: boolean
  hidden?: boolean
}

interface StepProps {
  step: number
  title: string
}

interface FormCTA {
  label: string
  onClick: () => void
  icon?: ReactNode
  disabled?: boolean
}

const iconWidth = '1em'

const icons = {
  Publicar: <PaperAirplaneIcon width={iconWidth} />,
  'Guardar activa': <PaperAirplaneIcon width={iconWidth} />,
  'Guardar activo': <PaperAirplaneIcon width={iconWidth} />,
  Activar: <PaperAirplaneIcon width={iconWidth} />,
  Guardar: <CloudArrowUpIcon width={iconWidth} />,
  'Guardar desactivada': <CloudArrowUpIcon width={iconWidth} />,
  'Guardar desactivado': <CloudArrowUpIcon width={iconWidth} />,
  Previsualizar: <EyeIcon width={iconWidth} />,
  'Sus emprendimientos': <BuildingOffice2Icon width={iconWidth} />,
  'Asociar emprendimientos': <BuildingOffice2Icon width={iconWidth} />,
  'Sus propiedades': <HomeIcon width={iconWidth} />,
  'Asociar propiedades': <HomeIcon width={iconWidth} />,
  Permisos: <KeyIcon width={iconWidth} />,
}

interface Props {
  steps: Step[]
  children?: JSX.Element
  onVisualizeStep?: (step: number) => void
  onThroughStep?: (step: number) => void
  onClickCapture?: (e: React.MouseEvent<HTMLButtonElement>) => void
  backButtonTarget?: string
  customBackButton?: JSX.Element
  hiddenProggressBar?: boolean
  hiddenButtons?: boolean
  style?: 'default' | 'joy'
  buttons?: React.ReactNode[] | FormCTA[]
  title?: string
  nextDisabled?: boolean
  isLoading?: boolean
  statusSelect?: JSX.Element
  isSaving?: boolean
  isSavingMessage?: string
  error?: boolean
  onErrorReload?: () => void
  forbidden?: boolean
  notFound?: boolean
  removeHeight?: number
}

const Stepper = ({
  customBackButton,
  steps: hiddenIncludedSteps,
  children,
  onVisualizeStep,
  onThroughStep,
  onClickCapture,
  backButtonTarget,
  hiddenProggressBar,
  hiddenButtons,
  buttons,
  style = 'default',
  title,
  nextDisabled,
  isLoading: loading = false,
  statusSelect,
  isSaving,
  isSavingMessage,
  error,
  forbidden,
  notFound,
  onErrorReload,
  removeHeight = 0,
}: Props) => {
  const isLoading = Boolean(loading || isSaving || error || forbidden || notFound)
  const steps = hiddenIncludedSteps.filter(({ hidden }) => !hidden)
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (onVisualizeStep) onVisualizeStep(activeStep ?? 0)
  }, [activeStep])

  const StepCheckBox = ({ step, title }: StepProps) => (
    <div
      className="py-3 border-bottom"
      onClick={() => setActiveStep(step)}
      key={`step-${step}`}
    >
      {steps[step].isLoading ? (
        <div style={{ cursor: 'pointer' }}>
          <Spinner
            animation="border"
            size="sm"
            className="spinner"
          />
          <label className={`form-check ${!steps[step].completed ? 'opacity-50' : ''}`}>{title}</label>
        </div>
      ) : (
        <Form.Check
          label={title}
          type="checkbox"
          style={{ cursor: 'pointer' }}
          checked={steps[step].completed}
          disabled={!steps[step].completed}
          onChange={() => null}
        />
      )}
    </div>
  )

  const completedPercentage = Math.round((steps.filter((step) => step.completed).length / steps.length) * 100)

  const showNavigationButtons = steps.length > 1

  const stepperTitleHeight = title ? globalStyles.navBarHeight : 0
  const stepTitleHeight = globalStyles.stepTitleHeight
  const customStepperHeight = showNavigationButtons ? globalStyles.stepperHeight : 0
  const buttonsHeight = hiddenButtons ? 0 : 66
  const barsHeight = stepperTitleHeight + stepTitleHeight + customStepperHeight + buttonsHeight + removeHeight
  const contentHeight = `calc(100vh - ${barsHeight}px)`

  const styles: JoyStyles = {
    titleBar: {
      height: stepTitleHeight,
      padding: 3,
    },
    content: {
      backgroundColor: 'var(--joy-palette-neutral-plainHoverBg)',
      paddingX: '30px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      height: contentHeight,
    },
    buttons: {
      paddingX: 3,
      paddingY: '15px',
      position: 'fixed',
      bottom: 0,
      width: '-webkit-fill-available', // TODO: Check browser compatibility
      borderTop: globalStyles.border,
      height: buttonsHeight,
      justifyContent: 'space-between',
    },
  }

  const isFormCTA = (buttons?: React.ReactNode[] | FormCTA[]): buttons is FormCTA[] => {
    return Boolean(typeof buttons?.[0] === 'object' && buttons[0] && 'label' in buttons[0])
  }

  const contentRef = useRef<HTMLDivElement>(null)

  return style === 'joy' ? (
    <>
      {title && (
        <StepperTitle
          isLoading={isLoading}
          title={title}
        />
      )}
      <CustomStepper
        steps={steps.map(({ completed }) => completed)}
        activeStep={activeStep}
        onThroughStep={onThroughStep}
        setActiveStep={setActiveStep}
        nextDisabled={nextDisabled}
        isLoading={isLoading}
      />
      <JoyStack
        id="titleBar"
        sx={styles.titleBar}
      >
        <JoyStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              level="h3"
              endDecorator={
                !isLoading &&
                steps[activeStep].subtitle && (
                  <InfoTooltip
                    width="14px"
                    library="hero"
                    color="var(--joy-palette-text-icon)"
                  >
                    {steps[activeStep].subtitle}
                  </InfoTooltip>
                )
              }
            >
              <Skeleton loading={isLoading}>
                {(showNavigationButtons ? `${activeStep + 1}- ` : '') + steps[activeStep].title}
              </Skeleton>
            </Typography>
          </Box>
          <Box>{statusSelect}</Box>
        </JoyStack>
      </JoyStack>

      <JoyStack
        id="content"
        sx={styles.content}
      >
        <Box
          ref={contentRef}
          sx={{
            overflowY: 'auto',
            backgroundColor: 'var(--joy-palette-background-surface)',
            padding: isLoading ? 0 : 3,
            borderRadius: globalStyles.borderRadius,
            height: `calc(100vh - ${barsHeight + 60}px)`,
            position: 'relative',
          }}
        >
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={'100%'}
                loading
              />
              <BaseModal
                icon="pending"
                container={contentRef.current ?? undefined}
                open={Boolean(isSaving)}
                title="Guardando"
                content={isSavingMessage ?? `Se está guardando el contenido`}
              />
              <BaseModal
                container={contentRef.current ?? undefined}
                icon="error"
                open={Boolean(error)}
                title="Error de carga"
                content="El elemento no cargó de manera correcta. Por favor cargar la página de vuelta"
                buttons={
                  <JoyButton
                    sx={{ width: '100%' }}
                    onClick={onErrorReload}
                  >
                    Volver a cargar
                  </JoyButton>
                }
              />
              <BaseModal
                container={contentRef.current ?? undefined}
                maxWidth="470px"
                open={Boolean(forbidden)}
                title="No tiene acceso al elemento"
                content={
                  <>
                    <Typography level="title-lg-bold">¿Como puedo acceder?</Typography>
                    <Typography
                      level="body-md-light"
                      color="neutral"
                    >
                      Contactate con el asesor de la empresa para que te brinde acceso a este elemento.
                    </Typography>
                  </>
                }
                buttons={
                  <JoyButton
                    sx={{ width: '100%' }}
                    onClick={() => navigate('/')}
                  >
                    Volver a inicio
                  </JoyButton>
                }
              />
              <BaseModal
                container={contentRef.current ?? undefined}
                icon="error"
                open={Boolean(notFound)}
                title="Elemento no encontrado"
                content="El elemento al que desea acceder no se encuentra disponible"
                buttons={
                  <JoyButton
                    sx={{ width: '100%' }}
                    onClick={() => navigate('/')}
                  >
                    Volver
                  </JoyButton>
                }
              />
            </>
          ) : (
            steps[activeStep].content
          )}
        </Box>
      </JoyStack>
      {!hiddenButtons && (
        <JoyStack
          id="buttons"
          direction="row"
          gap={isLoading ? 10 : undefined}
          sx={styles.buttons}
        >
          {isLoading ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  sx={{
                    visibility: !showNavigationButtons && index !== 1 ? 'hidden' : 'visible',
                  }}
                  loading={isLoading}
                  variant="rectangular"
                />
              ))}
            </>
          ) : (
            <>
              <BackButton
                disabled={!activeStep}
                sx={{ visibility: showNavigationButtons ? 'visible' : 'hidden', paddingX: 3 }}
                onClick={() => {
                  onThroughStep?.(activeStep)
                  setActiveStep((prevStep) => prevStep - 1)
                }}
              />
              <JoyStack
                direction={'row'}
                gap={2}
              >
                {isFormCTA(buttons!) ? (
                  <Dropdown>
                    <MenuButton
                      sx={{ paddingX: 6 }}
                      color="primary"
                      variant="solid"
                      endDecorator={<EllipsisVerticalIcon width={iconWidth} />}
                    >
                      <Typography level="title-lg-light">Guardar</Typography>
                    </MenuButton>
                    <Menu sx={{ zIndex: '9999' }}>
                      {buttons.map(({ label, onClick, icon, disabled }, index) => (
                        <Fragment key={index}>
                          {Boolean(index) && <Divider />}
                          <MenuItem
                            onClick={onClick}
                            disabled={disabled}
                          >
                            <Typography
                              sx={{ paddingX: 2 }}
                              startDecorator={icon ?? icons[label as keyof typeof icons]}
                              level="title-lg-light"
                            >
                              {label}
                            </Typography>
                          </MenuItem>
                        </Fragment>
                      ))}
                    </Menu>
                  </Dropdown>
                ) : (
                  buttons
                )}
              </JoyStack>
              <NextButton
                sx={{ visibility: showNavigationButtons ? 'visible' : 'hidden', paddingX: 3 }}
                disabled={activeStep > steps.length - 2 || nextDisabled}
                onClick={() => {
                  onThroughStep?.(activeStep)
                  setActiveStep((prevStep) => prevStep + 1)
                }}
              />
            </>
          )}
        </JoyStack>
      )}
    </>
  ) : (
    <Row className="stepper">
      <Col
        xs={6}
        md={7}
        lg={8}
        xl={9}
        className="px-5"
      >
        <Title title={steps[activeStep].title}></Title>
        <Row>{steps[activeStep].content}</Row>
        <Row onClickCapture={onClickCapture}>
          <div
            className={`py-4 my-5 d-flex justify-content-${
              customBackButton || backButtonTarget ? 'between' : 'end'
            } border-top`}
          >
            {customBackButton ??
              (!!backButtonTarget && (
                <Button
                  variant="link"
                  name="btnBack"
                  style={{ textDecoration: 'none' }}
                  onClick={() => navigate(backButtonTarget)}
                >
                  {'< Volver'}
                </Button>
              ))}
            <Stack
              direction="horizontal"
              gap={3}
            >
              <Button
                hidden={!activeStep}
                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
              >
                Anterior
              </Button>
              <Button
                hidden={activeStep > steps.length - 2}
                onClick={() => setActiveStep((prevStep) => prevStep + 1)}
              >
                Siguiente
              </Button>
            </Stack>
          </div>
        </Row>
      </Col>
      <Col
        onClickCapture={onClickCapture}
        xs={6}
        md={5}
        lg={4}
        xl={3}
        className="p-3 steplist-col"
      >
        <div className="p-5 stepList">
          {steps.map((step, index) => (
            <StepCheckBox
              key={`stepCheckbox-${index}`}
              step={index}
              title={step.title}
            />
          ))}

          {!hiddenProggressBar && (
            <>
              <div className="d-flex mt-5 progressBar">
                <Form.Label className="mb-0">Progreso</Form.Label>
                <Form.Text className="ms-auto">{completedPercentage}%</Form.Text>
              </div>
              <ProgressBar now={completedPercentage}></ProgressBar>
              <Form.Text>Carga de datos</Form.Text>
            </>
          )}
          {children}
        </div>
      </Col>
    </Row>
  )
}

interface CustomStepperProps {
  steps: boolean[]
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  nextDisabled?: boolean
  onThroughStep?: (step: number) => void
  isLoading?: boolean
}

const CustomStepper = memo(
  ({ steps, activeStep, setActiveStep, nextDisabled, onThroughStep, isLoading }: CustomStepperProps) => (
    <Box
      sx={{
        display: steps.length > 1 ? 'flex' : 'none',
        height: globalStyles.stepperHeight,
        alignItems: 'end',
      }}
    >
      <JoyStepper
        size="lg"
        sx={{
          width: '100%',
          display: steps.length > 1 ? 'flex' : 'none',
          paddingX: 3,
          alignContent: 'end',
          // borderBottom: globalStyles.border,
          height: '35px',
          '--Step-connectorInset': 0,
          '--Step-gap': 0,
          '--Step-connectorThickness': '2px',
          '--StepIndicator-size': '25px',
          [`& .${stepClasses.active}`]: {
            '--StepIndicator-size': '35px',
            [`& .${stepIndicatorClasses.root}`]: {
              backgroundColor: 'var(--joy-palette-primary-solidBg)',
            },
          },
          [`& .${stepClasses.completed}`]: {
            '&::after': { backgroundColor: 'var(--joy-palette-primary-solidBg)' },
            [`& .${stepIndicatorClasses.root}`]: {
              backgroundColor: 'var(--joy-palette-primary-solidBg)',
            },
          },
        }}
      >
        {steps?.map((completed, index) => (
          <Step
            active={activeStep === index}
            completed={completed && !isLoading}
            key={`step-${index}`}
            indicator={
              isLoading ? (
                <Avatar>
                  <Skeleton loading={isLoading} />
                </Avatar>
              ) : (
                <CustomStepIndicator
                  completed={completed}
                  index={index}
                  isActive={activeStep === index}
                  onClick={() => {
                    !nextDisabled && setActiveStep(index)
                    onThroughStep?.(activeStep)
                  }}
                />
              )
            }
          ></Step>
        ))}
      </JoyStepper>
    </Box>
  ),
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.steps) === JSON.stringify(nextProps.steps) &&
    prevProps.activeStep === nextProps.activeStep &&
    prevProps.nextDisabled === nextProps.nextDisabled &&
    prevProps.isLoading === nextProps.isLoading,
)

interface StepIndicatorProps {
  completed: boolean
  index: number
  isActive: boolean
  onClick?: () => void
}

const CustomStepIndicator = ({ completed, index, onClick, isActive }: StepIndicatorProps) => (
  <StepIndicator
    variant="solid"
    onClick={onClick}
    sx={{ cursor: 'pointer' }}
  >
    {completed ? (
      <CheckIcon
        width="1em"
        fontSize={isActive ? 'medium' : 'small'}
      />
    ) : (
      <Typography level={isActive ? 'title-lg-light' : 'body-md-light'}>{index + 1}</Typography>
    )}
  </StepIndicator>
)

interface ButtonProps {
  disabled?: boolean
}

const BackButton = ({ disabled, ...rest }: ButtonProps & React.ComponentProps<typeof JoyButton>) => (
  <JoyButton
    {...rest}
    startDecorator={<KeyboardBackspaceIcon />}
    variant="outlined"
    size="lg"
    disabled={disabled}
  >
    <Typography level="title-lg-light">Anterior</Typography>
  </JoyButton>
)

const NextButton = ({ disabled, ...rest }: ButtonProps & React.ComponentProps<typeof JoyButton>) => (
  <JoyButton
    {...rest}
    endDecorator={<KeyboardBackspaceIcon sx={{ transform: 'rotate(180deg)' }} />}
    variant="outlined"
    size="lg"
    disabled={disabled}
  >
    <Typography level="title-lg-light">Siguiente</Typography>
  </JoyButton>
)

interface CardProps {
  title: string
  subtitle: string
  content: React.ReactElement
  onClick: () => void
  selected?: boolean
  disabled?: boolean
}

interface CardSelectProps {
  title?: string
  cards: CardProps[]
  disabled?: boolean
}

export const CardSelect = ({ cards, title, disabled }: CardSelectProps) => {
  const styles: JoyStyles = {
    container: {
      // padding: 1,
      // border: globalStyles.border,
      // borderRadius: globalStyles.card.borderRadius,
    },
    gridItem: {
      display: 'flex',
    },
  }

  return (
    <JoyStack gap={2}>
      {title && (
        <Typography
          level="h3"
          color="neutral"
        >
          {title}
        </Typography>
      )}
      <Grid
        id="container"
        container
        spacing={2}
        sx={styles.container}
      >
        {cards.map((card, index) => (
          <Grid
            xs={12}
            sm={6}
            md={3}
            key={`card-${index}`}
            sx={styles.gridItem}
          >
            <JoyButton
              variant="outlined"
              color="neutral"
              onClick={card.onClick}
              sx={{
                height: '100%',
                backgroundColor: card.selected ? 'white' : '',
                opacity: card.selected === undefined || card.selected ? '100%' : '40%',
                '&:hover': card.selected ? { backgroundColor: 'white', cursor: 'default' } : {},
              }}
              disabled={disabled || card.disabled}
            >
              <JoyStack
                sx={{
                  height: '100%',
                  padding: 1,
                  justifyItems: 'start',
                  alignItems: 'left',
                }}
                gap={2}
              >
                {card.content}
                <Typography
                  level="title-lg-bold"
                  sx={{ textAlign: 'left' }}
                >
                  {card.title}
                </Typography>
                <Typography
                  level="body-sm-light"
                  sx={{ textAlign: 'left' }}
                >
                  {card.subtitle}
                </Typography>
              </JoyStack>
            </JoyButton>
          </Grid>
        ))}
      </Grid>
    </JoyStack>
  )
}

interface StepperAccordionSectionItem {
  title: string
  content: React.ReactElement
  hidden?: boolean
  isInvalid?: boolean
  errorFields?: string[]
  tooltip?: string
}

export interface StepperAccordionSection {
  title: string
  items: StepperAccordionSectionItem[]
  hidden?: boolean
}
interface StepperAccordionProps {
  sections: StepperAccordionSection[]
  hasBeenVisualized?: boolean
  defaultExpanded?: number
}

export const StepperAccordion = ({ sections, hasBeenVisualized, defaultExpanded }: StepperAccordionProps) => {
  const [lastExpandedIndex, setLastExpandedIndex] = useState<number | false>(0)
  const [hasBeenClicked, setHasBeenClicked] = useState(false)

  useEffect(() => {
    if (defaultExpanded) {
      setLastExpandedIndex(defaultExpanded)
    }
  }, [defaultExpanded])

  const hasUserInteracted = hasBeenVisualized || hasBeenClicked

  const styles: JoyStyles = {
    accordionGroup: {
      [`& .${accordionClasses.root}.form-accordion`]: {
        paddingY: 1,
      },
      [`& .${accordionClasses.root}.${accordionClasses.expanded}.form-accordion`]: {
        border: `1px solid ${'var(--joy-palette-primary-plainColor)'}`,
      },
      // [`& .${accordionDetailsClasses.root}:not(.${accordionDetailsClasses.expanded})`]: {
      //   display: 'none',
      // },
    },
    accordionDetailsContent: { paddingInline: 0, paddingTop: 3 },

    accordionSummary: { paddingX: 3 },
    accordionItemContent: { padding: 3 },
  }

  return (
    <AccordionGroup
      onClick={() => setHasBeenClicked(true)}
      size="lg"
      sx={styles.accordionGroup}
    >
      <JoyStack gap={1}>
        {sections
          .filter(({ items, hidden }) => !hidden && items.some(({ hidden }) => !hidden))
          .map(({ title, items }, index) => (
            <Accordion
              key={index}
              className="form-accordion"
              sx={{
                border:
                  hasUserInteracted &&
                  items.some(({ isInvalid, errorFields }) => isInvalid || Boolean(errorFields?.length))
                    ? globalStyles.borderError
                    : globalStyles.border,
                borderRadius: globalStyles.borderRadius,
              }}
              expanded={lastExpandedIndex === index}
              onChange={() => setLastExpandedIndex((prev) => (prev === index ? false : index))}
            >
              <AccordionSummary sx={styles.accordionSummary}>
                <JoyStack
                  sx={{ width: '100%' }}
                  direction="row"
                  justifyContent={'space-between'}
                >
                  <Typography
                    level="title-lg-bold"
                    variant="plain"
                  >
                    {title}
                  </Typography>
                  {hasUserInteracted &&
                    (items.some(({ errorFields }) => Boolean(errorFields?.length)) ? (
                      <Tooltip
                        title={
                          <Stack gap={2}>
                            <Typography level="body-sm-bold">Verifique los siguientes elementos:</Typography>
                            <Stack>
                              {items
                                .flatMap(({ errorFields }) => errorFields ?? [])
                                .map((errorField, index) => (
                                  <Typography
                                    key={index}
                                    level="body-sm-light"
                                  >
                                    {'- ' + errorField}
                                  </Typography>
                                ))}
                            </Stack>
                          </Stack>
                        }
                      >
                        <Typography
                          level="title-lg-bold"
                          color="danger"
                          endDecorator={<InformationCircleIcon width={'1.2em'} />}
                        >
                          {items.flatMap(({ errorFields }) => errorFields ?? []).length}
                        </Typography>
                      </Tooltip>
                    ) : (
                      items.some(({ isInvalid, errorFields }) => isInvalid || Boolean(errorFields?.length)) && (
                        <Typography
                          level="title-lg-bold"
                          color="danger"
                          endDecorator={<InformationCircleIcon width={'1.2em'} />}
                        >
                          {items.filter(({ isInvalid }) => isInvalid).length}
                        </Typography>
                      )
                    ))}
                </JoyStack>
              </AccordionSummary>
              <AccordionDetails slotProps={{ content: { sx: styles.accordionDetailsContent } }}>
                {items
                  .filter(({ hidden }) => !hidden)
                  .map((item, index) => (
                    <JoyStack key={index}>
                      <DividerTypography
                        tooltip={item.tooltip}
                        title={item.title}
                        isInvalid={(item.isInvalid || Boolean(item.errorFields?.length)) && hasUserInteracted}
                        color={
                          (item.isInvalid || Boolean(item.errorFields?.length)) && hasUserInteracted
                            ? 'var(--joy-palette-danger-outlinedColor)'
                            : undefined
                        }
                      />
                      <JoyStack sx={styles.accordionItemContent}>{item.content}</JoyStack>
                    </JoyStack>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </JoyStack>
    </AccordionGroup>
  )
}

const DividerTypography = memo(
  ({
    title,
    color,
    isInvalid,
    tooltip,
  }: {
    title: string
    color?: CSSProperties['color']
    isInvalid?: boolean
    tooltip?: string
  }) => (
    <Divider
      sx={{
        '--Divider-childPosition': `3%`,
        color: color ?? 'var(--joy-palette-primary-plainColor)',
        '&::before, &::after': {
          background: color ?? 'var(--joy-palette-primary-plainColor)',
        },
      }}
    >
      <Typography
        color={isInvalid ? 'danger' : 'primary'}
        level="body-md-bold"
        endDecorator={
          tooltip && (
            <InfoTooltip
              width="14px"
              color="var(--joy-palette-text-icon)"
            >
              {tooltip}
            </InfoTooltip>
          )
        }
      >
        {title}
      </Typography>
    </Divider>
  ),
)

interface GalleryItemProps {
  file: File | string | null
  onDelete?: () => void
}

export const GalleryItem = ({ file, onDelete }: GalleryItemProps) => {
  const styles: JoyStyles = {
    aspectRatioStack: {
      justifyContent: 'space-between',
      padding: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
  }

  return (
    <AspectRatio
      objectFit="contain"
      sx={{ position: 'relative', borderRadius: globalStyles.borderRadius }}
    >
      <img
        draggable={false}
        src={typeof file === 'string' ? file : file instanceof File ? URL.createObjectURL(file) : ''}
        style={{ cursor: 'grabbing' }}
      />
      <JoyStack
        direction="row"
        sx={styles.aspectRatioStack}
      >
        <IconButton
          variant="soft"
          disabled
        >
          <ArrowsPointingOutIcon style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
        <IconButton
          variant="soft"
          onClick={() => onDelete?.()}
        >
          <TrashIcon />
        </IconButton>
      </JoyStack>
    </AspectRatio>
  )
}

export default Stepper

interface StepperTitleProps {
  title: string
  isLoading?: boolean
}

const StepperTitle = memo(({ title, isLoading }: StepperTitleProps) => (
  <JoyStack
    justifyContent="space-between"
    direction="row"
    alignItems="center"
    sx={{
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    }}
  >
    <Typography level="h4">
      <Skeleton loading={isLoading}>{title}</Skeleton>
    </Typography>
    <ModalClose
      size="lg"
      sx={{
        position: 'relative',
        right: 0,
        top: 0,
      }}
    />
  </JoyStack>
))
