import { Button, DialogContent, DialogTitle, Modal, ModalDialog, Stack } from '@mui/joy'
import React from 'react'

interface ModalProps {
  header: string
  body: React.ReactNode
  show: boolean
  onAccept: () => void
  onCancel?: () => void
}

interface Props {
  modal: ModalProps
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
  acceptText?: string
  loading?: boolean
  cancelText?: string
}

const ConfirmationModal = ({ modal, setModal, acceptText = 'Aceptar', cancelText = 'Cancelar', loading }: Props) => {
  return (
    <Modal
      open={modal.show}
      onClose={() => setModal((prevState) => ({ ...prevState, show: false }))}
    >
      <ModalDialog>
        <DialogTitle>{modal.header}</DialogTitle>
        <DialogContent>{modal.body}</DialogContent>
        <Stack
          direction="row"
          gap={1}
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="neutral"
            disabled={loading}
            onClick={() => {
              setModal((prevState) => ({ ...prevState, show: false }))
              if (modal.onCancel) modal.onCancel()
            }}
          >
            {cancelText}
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              setModal((prevState) => ({ ...prevState, show: false }))
              modal.onAccept()
            }}
          >
            {acceptText}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

export default ConfirmationModal
